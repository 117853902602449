import React, { useState, useEffect, useRef } from "react";
import useFetch from "use-http";
import useChannel from "../components/useChannel";
import DeviceMessage from "../components/DeviceMessage";
import { Box, List } from "@mui/material";
import SocketStatus from "../components/SocketStatus";
import ChannelStatus from "../components/ChannelStatus";
import Loader from "../components/Loader";
import ReactJsonViewer from "react-json-viewer-cool";
import { useTranslation } from "react-i18next";

const DeviceMessages = ({ device }) => {
	const MESSAGES_INCOMING = "messages_incoming";
	const MESSAGE_LIMIT = 100;
	const {
		channel,
		channelConnected,
		socketConnected,
		subscribeToChannelEvent,
		unsubscribeToChannelEvent,
		message,
		push,
		leaveChannel,
		connect,
	} = useChannel(`device_message:${device.id}`);
	const { get, loading, error } = useFetch(
		`${process.env.REACT_APP_SWARM_BASEURL}`
	);
	const { t } = useTranslation();
	const [messages, setMessages] = useState();
	const [selectedMessage, setSelectedMessage] = useState();
	const [timeoutRef, setTimeoutRef] = useState();
	const messagesRef = useRef(messages);

	const cleareMessages = () => {
		const newMessages = messagesRef.current.map((m) => {
			m.inserted = false;
			return m;
		});
		setMessages(newMessages);
	};

	useEffect(() => {
		if (message) {
			const payloads = message.payload.map((p) => {
				p.inserted = true;
				return p;
			});

			if (timeoutRef) {
				clearTimeout(timeoutRef);
			}
			const newMessages = payloads.concat(messages).splice(0, MESSAGE_LIMIT);
			messagesRef.current = newMessages;
			setMessages(newMessages);
			setTimeoutRef(setTimeout(() => cleareMessages(), 10000));
		} else {
			get(
				`/api/v1/altior/digital_twin/${device.attributes.digital_twin_id}/instance/${device.id}/message`
			).then((data) => {
				setMessages(
					data.data.map((item) => item.attributes).splice(0, MESSAGE_LIMIT)
				);
			});
		}
	}, [message]);

	useEffect(() => {
		if (!channel) {
			const phoenixChannel = connect();
			return
		}

		subscribeToChannelEvent(MESSAGES_INCOMING);

		// stop listening to this message before the component unmounts
		return () => {
			unsubscribeToChannelEvent(MESSAGES_INCOMING);
			leaveChannel();
		};
	}, [channel]);

	if (loading || !messages) {
		return <Loader />;
	}

	return (
		<>
			<Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
				<SocketStatus isConnected={socketConnected} />
				<ChannelStatus isConnected={channelConnected} />
			</Box>
			<Box
				sx={{
					display: "flex",
					gap: 2,
					height: "69vh",
				}}
			>
				<Box
					sx={{
						flex: 1,
						width: "auto",
						overflow: "hidden",
						maxHeight: "100%",
					}}
				>
					<List
						sx={{
							overflowY: "scroll",
							whiteSpace: "nowrap",
							height: "100%",
							width: "auto",
						}}
					>
						{messages.map((m) => (
							<DeviceMessage
								selected={selectedMessage}
								message={m}
								onClick={(sm) => {
									setSelectedMessage(sm);
								}}
							/>
						))}
					</List>
				</Box>
				<Box sx={{ flex: [1, 1] }}>
					{!selectedMessage ? (
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100%",
							}}
						>
							{" "}
							{t("no_message_selected")}{" "}
						</Box>
					) : (
						<Box sx={{ overflowY: "scroll", height: "100%" }}>
							<ReactJsonViewer data={selectedMessage} />
						</Box>
					)}
				</Box>
			</Box>
		</>
	);
};

export default DeviceMessages;
