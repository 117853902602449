import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GatewayExportButton from "../form/formComponents/GatewayExportButton";
import { useNavigate } from "react-router-dom";
import { store } from "../../redux/store";
import SearchOnServer from "../form/formComponents/SearchOnServer";
import { prepareParams } from "../../utils";
import DatePicker from "../form/formComponents/DatePicker";
import SingleDatePicker from "../form/formComponents/SingleDatePicker";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";

const diameterEnum = [
  { const: "DN6" },
  { const: "DN8" },
  { const: "DN10" },
  { const: "DN15" },
  { const: "DN20" },
  { const: "DN25" },
  { const: "DN32" },
  { const: "DN40" },
  { const: "DN50" },
  { const: "DN65" },
  { const: "DN80" },
  { const: "DN90" },
  { const: "DN100" },
  { const: "DN125" },
  { const: "DN150" },
  { const: "DN200" },
  { const: "DN250" },
  { const: "DN300" },
  { const: "DN350" },
  { const: "DN400" },
  { const: "DN450" },
  { const: "DN500" },
  { const: "DN550" },
  { const: "DN600" },
  { const: "DN650" },
  { const: "DN700" },
  { const: "DN750" },
  { const: "DN800" },
  { const: "DN900" },
  { const: "DN1000" },
  { const: "DN1050" },
  { const: "DN1200" },
  { const: "DN1400" },
  { const: "DN1600" },
  { const: "DN1800" },
  { const: "DN2000" },
  { const: "DN2200" },
  { const: "DN2400" },
  { const: "DN2500" },
  { const: "DN2600" },
  { const: "DN2700" },
];
const objectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={3}>
      {props.properties.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const GatewayIndexForm = ({
  page,
  perPage,
  setPage,
  setParams,
  paramsList,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [refreshParams, setRefreshParams] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  let [searchParams, setSearchParams] = useSearchParams();
  const [formData, setFormData] = useState({});
  const [getIndexGateway] = store.useLazyGetIndexGatewayQuery();
  const gayeways = useSelector((state) => state?.gatewaySlice?.gataways);
  useEffect(() => {
    setParams(paramsList);
  }, [
    paramsList?.serial_number,
    paramsList?.modem_imei,
    paramsList?.per_page,
    paramsList.page,
    paramsList?.class,
  ]);
  const schema = {
    title: "Filter form",
    type: "object",
    properties: {
      name: {
        id: 1,
        title: t("name"),
        type: "string",
        default: paramsList.name,
      },
      serial_number: {
        id: 2,
        title: t("serial_number"),
        type: "string",
        default: paramsList.serial_number,
      },
      modem_imei: {
        id: 3,
        title: t("modem_imei"),
        type: "string",
        default: paramsList.modem_imei,
      },
      inserted_at: {
        id: 4,
        title: t("configuration_date"),
        type: "object",
        type: "string",
        label: "configuration_date",
      },
    },
  };
  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: t("search"),

      props: {
        className: "bntListSearch",
      },
    },
    "ui:ObjectFieldTemplate": objectFieldTemplate,
    digital_twin_id: {
      "ui:field": "serverSearch",
    },
    tags: {
      "ui:field": "serverSearch",
    },
    inserted_at: {
      "ui:field": "datepicker",
    },
  };
  const fields = {
    serverSearch: SearchOnServer,
    datepicker: SingleDatePicker,
  };
  useEffect(() => {
    if (!gayeways && firstRender) {
      getIndexGateway({ ...paramsList });
    }
    setFirstRender(false);
  });
  useEffect(() => {
    if (!firstRender) {
      getIndexGateway({ ...paramsList });
    }
  }, [page]);
  useEffect(() => {
    if (refreshParams) {
      getIndexGateway({ ...paramsList });
      setRefreshParams(false);
    }
  }, [refreshParams, page]);
  const customValidate = (formData, errors) => {
    if (formData.insertion_date.from && formData.insertion_date.to) {
      if (
        formData.insertion_date.from !== "" &&
        formData.insertion_date.to !== "" &&
        formData.insertion_date.from >= formData.insertion_date.to
      ) {
        errors.insertion_date.to.addError(t("date_error"));
      }
    } else {
      if (formData.insertion_date.from && !formData.insertion_date.to) {
        errors.insertion_date.to.addError(t("missing_endDate"));
      }
      if (!formData.insertion_date.from && formData.insertion_date.to) {
        errors.insertion_date.from.addError(t("missing_startDate"));
      }
    }

    return errors;
  };
  const onSubmitClick = (obj) => {
    const params = prepareParams(obj);
    navigate({
      pathname: "/gateways",
      search: params.toString(),
    });
    setPage(1);
    setRefreshParams(true);
  };

  return (
    <Form
      schema={schema}
      fields={fields}
      formData={formData}
      /*   customValidate={customValidate} */
      showErrorList={false}
      onChange={(changeEvent) => setFormData(changeEvent.formData)}
      /*     onSubmit={(values) => {
        onSubmitClick(values.formData);
      }} */
      validator={validator}
      uiSchema={uiSchema}
    >
      <Button
        variant="contained"
        sx={{ mt: 3, float: "right" }}
        type="submit"
        onClick={() => onSubmitClick(formData)}
      >
        {t("search")}
      </Button>
      <GatewayExportButton styleProp={{ float: "right", mr: 2, mt: 3 }} />
    </Form>
  );
};
export default GatewayIndexForm;
