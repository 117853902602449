import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { store } from "../../redux/store";
import { Button, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import theme from "../../theme";
import ColorPickerWidget from "./ColorPickerWidget";
import ImageUpload from "./ImageUpload";
import { useParams } from "react-router-dom";

const ObjectFieldTemplate = (props) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      rowSpacing={3}
      spacing={2}
    >
      {props.properties.map((element, i) => {
        if (element.name === "colors") {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const ConfigShow = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const config = useSelector((state) => state?.configurationSlice?.configShow);
  const [updateConfig, { isSuccess }] = store.useUpdateConfigurationMutation();
  const [getConfig] = store.useLazyGetConfigShowQuery();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const schema = {
    type: "object",
    properties: {
      name: {
        $id: "1",
        type: "string",
        title: t("name"),
        default: config?.name,
      },
      url: {
        $id: "1",
        type: "string",
        title: t("url"),
        default: config?.url,
      },

      logo_id: {
        type: ["number", "string"],
        title: t("logo"),
        default: config?.logo_id,
        update: true,
      },
      favicon_id: {
        type: ["number", "string"],
        title: t("fav icon"),
        default: config?.favicon_id,
        update: true,
      },
      colors: {
        type: "object",
        properties: {
          primary: {
            type: "string",
            default: config?.colors?.primary,
          },
          secondary: {
            type: "string",
            default: config?.colors?.secondary,
          },
        },
      },
    },
    required: ["name"],
  };
  const fields = {
    colorpicker: ColorPickerWidget,
    img: ImageUpload,
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    "ui:submitButtonOptions": {
      submitText: t("update"),
    },
    colors: {
      "ui:field": "colorpicker",
    },
    logo_id: {
      "ui:field": "img",
    },
    favicon_id: {
      "ui:field": "img",
    },
  };
  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessage(t("update_success")));
    }
  }, [isSuccess]);
  useEffect(() => {
    console.log("frmdt", formData);
  }, [formData]);
  const onSubmitClick = () => {
    console.log("saddd", formData);
    const attributes = {
      logo_id: formData?.logo_id,
      favicon_id: formData?.favicon_id,
      name: formData?.name,
      url: formData?.url,
      colors: {
        primary: formData?.colors?.primary,
        secondary: formData?.colors?.secondary,
      },
    };
    updateConfig({
      id: id,
      attributes: attributes,
    });
  };
  const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-.\/?%&=]*)?$/;
  const customValidate = (formData, errors) => {
    if (!urlRegex.test(formData?.url)) {
      errors?.url?.addError("not valid url");
    }
    return errors;
  };
  useEffect(() => {
    getConfig(id);
  }, [id]);
  useEffect(() => {
    console.log("wtfceeeel", formData);
  }, [formData]);
  return (
    <>
      {config && (
        <>
          <Typography
            color={theme.palette.primary.main}
            variant="h1"
            sx={{ mt: 2, mb: 3 }}
          >
            {t("update_config")}
          </Typography>
          <Form
            schema={schema}
            formData={formData}
            onChange={(changeEvent) => setFormData(changeEvent.formData)}
            onSubmit={onSubmitClick}
            validator={validator}
            uiSchema={uiSchema}
            /* widgets={widgets} */
            showErrorList={false}
            fields={fields}
            customValidate={customValidate}
            noHtml5Validate
          />
        </>
      )}
    </>
  );
};
export default ConfigShow;
