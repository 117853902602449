import React, { useEffect, useState } from "react";
import ImgUpload from "./ImgUpload";
import { Box, Typography } from "@mui/material";
import theme from "../../theme";

const ImageBox = ({ imgId, text, seveIdFn, success, msg }) => {
  const bearer = localStorage.getItem("user_token");
  const [imageUrl, setImageUrl] = useState(
    `${window.BASE_URL}/api/v1/image/${imgId}?token=${bearer}` // Placeholder URL for default image
  );

  useEffect(() => {
    debugger;
    if (imgId) {
      setImageUrl(`${window.BASE_URL}/api/v1/image/${imgId}?token=${bearer}`);
    }
  }, [imgId]);
  useEffect(() => {
    if (success) {
      window.location.reload();
    }
  }, [success]);
  return (
    <>
      <Typography
        sx={{ mb: 2 }}
        color={theme.palette.primary.main}
        variant="h3"
      >
        {text}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "24px",
          border: "2px solid #ccc",
          borderRadius: "12px",
          width: "100%",
          margin: "auto",
        }}
      >
        {/* Image Display Section */}
        <img src={imageUrl} alt="Uploaded" style={{ borderRadius: "12px" }} />

        {/* Image Upload Section */}
        <Box sx={{ marginLeft: "24px" }}>
          <ImgUpload seveIdFn={seveIdFn} msg={msg} />
        </Box>
      </Box>
    </>
  );
};

export default ImageBox;
