import { StyledDataGrid } from "./StyledDataGrid";
import { useEffect, useTransition } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import Box from "@mui/material/Box";
import {
  IconButton,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import theme from "../theme";
const InfiniteTable = ({
  columns,
  rows,
  styles,
  paginationMode,
  page,
  perPage,
  setPage,
  isSuccess,
  records,
  recorsLength,
  recordsFn,
}) => {
  const [actualPage, setActualPage] = useState(null);
  function CustomPagination({
    setPage,
    isSuccess,
    parentPage,
    records,
    recordsFn,
    actualPage,
    setActualPage,
    setTotalRows,
    recorsLength,
    perPage,
  }) {
    const dispatch = useDispatch();
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, (state) => state.pagination.page);

    const [newPage, setNewPage] = useState(null);
    let newpage;
    const pageCount = useGridSelector(
      apiRef,
      (state) => state.pagination.pageCount
    );
    useEffect(() => {
      console.log("recrecrec", actualPage);
    }, [actualPage]);
    const handleChange = (event, value) => {
      setPage(value);
      setActualPage(value);
      console.log("recrecrecbec");

      dispatch(recordsFn("waiting"));
    };
    useEffect(() => {
      if (parentPage && records == "havedata") {
        apiRef.current.setPage(parentPage - 1);
      }
    }, [parentPage, records]);

    useEffect(() => {
      if (records == "nodata") {
        debugger;

        setPage(actualPage);
        apiRef.current.setPage(actualPage);
        setTotalRows(actualPage * perPage);
      }
    }, [records]);
    return (
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={handleChange}
      />
    );
  }

  const [totalRows, setTotalRows] = useState(Infinity); // Устанавливаем totalRows в Infinity

  return (
    <Box sx={{ ...styles }}>
      <StyledDataGrid
        /*     getRowClassName={(params) => styleSelector(params)} */
        disableColumnMenu
        rows={rows ?? []}
        rowCountText={"..."}
        columns={columns}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        disableSelectionOnClick
        /*      onPageChange={(pageNumber) => {
          setPage(pageNumber + 1);
        }}
        page={page - 1} */
        components={{
          Pagination: () => (
            <CustomPagination
              isSuccess={isSuccess}
              setPage={setPage}
              parentPage={page}
              records={records}
              recordsFn={recordsFn}
              actualPage={actualPage}
              setActualPage={setActualPage}
              setTotalRows={setTotalRows}
              recorsLength={recorsLength}
              perPage={perPage}
            />
          ), // Передаем setPage как пропс
        }}
        // loading={isLoadingTable}
        paginationMode={paginationMode}
        rowCount={totalRows}
        rowLength={3}
        getRowHeight={() => "auto"}

        /*   hideFooter={true} */
      />
      {/*    <Card sx={{ height: 65 }}>
        <CardContent>
          <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
            <IconButton disabled={page == 1} onClick={() => setPage(page - 1)}>
              <ArrowBackIosIcon></ArrowBackIosIcon>
            </IconButton>
            <div style={{ textAlign: "center" }}>
              <div
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {page}
              </div>
            </div>
            <IconButton onClick={() => setPage(page + 1)}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </CardContent>
      </Card> */}
    </Box>
  );
};

export default InfiniteTable;
