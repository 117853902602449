import { useEffect, useState } from "react";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { store } from "../../redux/store";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import {
  setConfigTrashId,
  setConfigShow,
} from "../../redux/slices/configurationSlice";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { Button } from "@mui/material";
import { setMessage } from "../../redux/slices/snackbarSlice";

const ConfigIndex = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteConfig, { isSuccess }] = store.useDeleteConfigurationMutation();
  const [deleteModal, setDeleteModal] = useState(false);
  const [trashId, setTrashId] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [getConfigIndex] = store.useLazyGetConfigIndexQuery();
  const configs = useSelector(
    (state) => state?.configurationSlice?.configIndex
  );
  const count = useSelector((state) => state?.configurationSlice?.configCount);
  const configTrashId = useSelector(
    (state) => state?.configurationSlice?.configTrashId
  );
  useEffect(() => {
    getConfigIndex({
      page: page,
      per_page: perPage,
    });
  }, [page, isSuccess]);
  const onTrashClick = (id) => {
    setTrashId(id);
    setDeleteModal(true);
  };
  const columns = [
    { field: "id", headerName: "ID", width: 90, sortable: false, flex: 1 },
    {
      field: "name",
      headerName: t("name"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
      headerAlign: "left",
    },
    {
      field: "url",
      headerName: t("url"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
      headerAlign: "left",
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "boolean",
      sortable: false,
      editable: false,
      headerAlign: "right",

      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%" }}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <IconButton sx={{ mt: 1 }}>
              <Link to={`/enduser/configs/${params.value}`}>
                <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
              </Link>
            </IconButton>
            <IconButton onClick={() => onTrashClick(params?.value)}>
              <DeleteIcon sx={{ fill: theme.palette.error.main }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const rows = configs?.map((item) => ({
    id: item?.id,
    name: item?.attributes?.name,
    url: item?.attributes?.url,
    actions: item?.id,
  }));
  useEffect(() => {
    if (configTrashId) {
      deleteConfig(configTrashId);
      dispatch(setConfigTrashId(null));
    }
  }, [configTrashId]);
  useEffect(() => {
    dispatch(setConfigShow(null));
  }, []);
  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessage(t("successfully_deleted")));
    }
  }, [isSuccess]);
  return (
    <Box sx={{ height: 600, width: "99%" }}>
      <Typography
        variant="h1"
        color={theme.palette.primary.main}
        sx={{ mt: 2, mb: 2 }}
      >
        {t("enduser_config")}
      </Typography>
      <DeleteConfirmModal
        trashModal={deleteModal}
        setTrashModal={setDeleteModal}
        text={t("config_delete_text")}
        title={t("config_delete_title")}
        deleteFunc={() => setConfigTrashId(trashId)}
      />
      <StyledDataGrid
        disableColumnMenu
        rows={rows ?? []}
        columns={columns}
        rowCount={count}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        disableSelectionOnClick
        onPageChange={(pageNumber) => {
          setPage(pageNumber + 1);
        }}
        page={page - 1}
        // loading={isLoadingTable}
        paginationMode="server"
        getRowHeight={() => "auto"}
      />
      <Button
        onClick={() => navigate("/enduser/configs/create")}
        variant="contained"
        sx={{ mt: 2, float: "right" }}
      >
        {t("create_config")}
      </Button>
    </Box>
  );
};
export default ConfigIndex;
