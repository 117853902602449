import { createSlice } from "@reduxjs/toolkit";
import { store } from "../store";
const initialState = {
  dashboard: null,
  updating: true,
  widgets: [],
  widget_ids: [],
  dashboards: [],
  dashboardId: null,
  layout: null,
  description: null,
  dashboardShow: null,
  dragBlock: false,
  tempWidgets: [],
  widgetsToRestore: [],
};

const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {
    createOrUpdate(state, action) {
      debugger;
      state.dashboard = action.payload;
      state.widgets = action.payload.widgets;
      state.widget_ids = action.payload.widget_ids;
      state.layout = action.payload.layout;
      state.description = action.payload.description;
    },
    updateDashboardData(state, action) {
      if (state.updating) {
        debugger;

        const currentWidgets = [...state.dashboard?.widgets];

        const newWidgets = action.payload[action.payload.length - 1].widgets
          ?.filter((n) => n)
          ?.map((w) => {
            const currentWidget = currentWidgets.find(
              (cw) => cw.id == w.widget_id
            );
            return {
              ...currentWidget,
              last_error: w.last_error,
              last_update: w.last_update,
            };
          });

        state.dashboard = {
          ...state.dashboard,
          widgets: newWidgets,
          last_update:
            action.payload[action.payload.length - 1].dashboard?.last_update,
        };
        state.widgets = [...newWidgets];
      }
    },
    setTempWidgets(state, action) {
      state.tempWidgets = [...state.tempWidgets, action.payload];
    },
    clearTempWidgets(state) {
      state.tempWidgets = [];
    },
    deleteTempWidgets(state) {
      state.widgets = state.widgets.filter(
        (item) => !state.tempWidgets.includes(item.id)
      );
      state.widget_ids = state.widget_ids?.filter(
        (item) => !state.tempWidgets.includes(item)
      );
      state.dashboard.widgets = state.dashboard.widgets.filter(
        (item) => !state.tempWidgets.includes(item.id)
      );
      state.dashboard.widget_ids = state.dashboard.widget_ids.filter(
        (item) => !state.tempWidgets.includes(item)
      );
    },
    setWidgetsToRestore(state, action) {
      state.widgetsToRestore = [...state.widgetsToRestore, action.payload];
    },
    restoreWidgets(state, action) {
      debugger;
      if (state.widgetsToRestore.length > 0) {
        state.widgets = [...state.widgets, ...state.widgetsToRestore];

        state.widget_ids = [...state.widget_ids, ...action.payload];
      }
    },
    clearRestoreWidgets(state) {
      state.widgetsToRestore = [];
    },
    // delete widget from dashboard with modal controll
    deleteWidget(state, action) {
      state.widgets = state.widgets?.filter(
        (item) => item.id !== action.payload
      );
      state.widget_ids = state.widget_ids?.filter(
        (item) => item !== action.payload
      );

      state.dashboard.widgets = state.dashboard.widgets?.filter(
        (item) => item.id !== action.payload
      );
      state.dashboard.widget_ids = state.dashboard.widget_ids?.filter(
        (item) => item !== action.payload
      );
    },
    // delete widget on creation phase without modal controll
    deleteCreatedWidget(state, action) {
      state.widgets = state.widgets?.filter(
        (item) => item.id !== action.payload
      );
      state.widget_ids = state.widget_ids?.filter(
        (item) => item !== action.payload
      );
    },
    updatingOn(state) {
      state.updating = true;
    },

    updatingOff(state) {
      state.updating = false;
    },
    addWidgetId(state, action) {
      if (typeof action.payload === "number") {
        /* state.dashboard.widget_ids = [
          ...state.dashboard.widget_ids,
          action.payload,
        ]; */
        state.widget_ids = [...state.widget_ids, action.payload];
      }
    },
    setDragBlcok(state, action) {
      state.dragBlock = action.payload;
    },
    addWidget(state, action) {
      if (
        !action?.payload?.data &&
        action.payload !== undefined &&
        action.payload !== null
      ) {
        /*  state.dashboard.widgets = [...state.dashboard.widgets, action.payload]; */
        state.widgets = [...state.widgets, action.payload];
      }
    },
    clearWidgets(state) {
      state.widgets = [];
      state.widget_ids = [];
      state.layout = null;
      state.dashboardShow = null;
    },
    deleteFromDashboardList(state, action) {
      state.dashboards = state.dashboards?.filter(
        (item) => item.id !== action.payload
      );
    },
    setDashboardId(state, action) {
      state.dashboardId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.dashboardEndpoints.getDashboardList.matchFulfilled,
      (state, action) => {
        state.dashboards = action.payload.data.attributes.data;
      }
    );
    builder.addMatcher(
      store.dashboardEndpoints.getDashboardShow.matchFulfilled,
      (state, action) => {
        state.dashboardShow = action.payload.data.attributes;
      }
    );
  },
});
export const {
  createOrUpdate,
  updateDashboardData,
  deleteWidget,
  updatingOn,
  updatingOff,
  addWidgetId,
  addWidget,
  clearWidgets,
  deleteCreatedWidget,
  deleteFromDashboardList,
  setDashboardId,
  setDragBlcok,
  setTempWidgets,
  clearTempWidgets,
  deleteTempWidgets,
  setWidgetsToRestore,
  restoreWidgets,
  clearRestoreWidgets,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
