import { useTranslation } from "react-i18next";
export const nameTransform = (name) => {
  const nameInfo = name?.split(":");
  const firstPart = parseInt(nameInfo[1], 16)?.toString();
  const secondPart = parseInt(nameInfo[2], 16)?.toString();
  if (isNaN(firstPart) || isNaN(secondPart)) {
    return name;
  }
  console.log("part1", typeof firstPart);
  console.log("part2", secondPart);
  const result = `${nameInfo[0]}:${
    firstPart?.length > 1 ? firstPart : "0" + firstPart
  }:${secondPart?.length > 1 ? secondPart : "0" + secondPart}`;
  console.log("namerez", nameInfo);
  return result;
};
export const typeTransform = (type) => {
  const typeLength = type?.toString()?.length;
  const res = typeLength === 1 ? `0${type}` : type;
  return res;
};

export function isMoreThanSecondsAgo(timestamp, seconds) {
  const now = new Date().getTime();
  const differenceInSeconds = (now - timestamp) / 1000;

  return differenceInSeconds >= seconds;
}

export const areArraysEqual = (arr1, arr2) =>
  arr1.length === arr2.length &&
  arr1.every((element, index) => element === arr2[index]);
