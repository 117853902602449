import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { setMessage } from "../../redux/slices/snackbarSlice";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import Card from "@mui/material/Card";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",

    alignItems: "center",
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    width: "97%",
    height: 300,
    overflow: "hidden",
  },
  imageContainer: {
    marginRight: 4,
    width: "100%", // Полная ширина
    height: "100%", // Полная высота
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    maxWidth: "100%", // Изображение должно подстраиваться под размер контейнера
    maxHeight: "100%", // Изображение должно подстраиваться под размер контейнера
    objectFit: "cover", // Растягиваем изображение на весь размер контейнера, сохраняя пропорции
    marginBottom: theme.spacing(1), // Небольшой отступ снизу
  },
  input: {
    position: "absolute",
    opacity: 0,
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    cursor: "pointer",
  },
}));

const ImageUploader = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const formData = new FormData();
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    setFile(file);
    reader.onloadend = () => {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const bearer = localStorage.getItem("user_token");
  useEffect(() => {
    console.log("propoxiki", props);
    if (props?.schema?.update) {
      setImage(
        `${window.BASE_URL}/api/v1/image/${props?.schema?.default}?token=${bearer}`
      );
      props.onChange(props?.schema?.default);
    }
  }, []);
  useEffect(() => {
    console.log(`image ${props.schema.title}`, image);
  }, [image]);
  const uploadFile = () => {
    formData.append("image", file);
    setLoading(true);
    fetch(`${window.BASE_URL}/api/v1/image`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${bearer}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return response.json();
      })
      .then((data) => {
        console.log("Response data:", data);
        props.onChange(data?.data?.id);
        setFile(null);
        setLoading(false);
        dispatch(setMessage(t("upload_success")));
      })
      .catch((error) => {
        console.error(":Erroriamba", error);
        setLoading(false);
      });
  };

  return (
    <>
      <Card className={classes.container}>
        <Typography textAlign={"center"}>{props?.schema?.title}</Typography>
        <Paper className={classes.container} elevation={3}>
          <div className={classes.imageContainer}>
            {loading ? (
              <CircularProgress />
            ) : (
              image && (
                <img src={image} alt="Uploaded" className={classes.image} />
              )
            )}
          </div>
        </Paper>
        <Box display={"flex"}>
          <label htmlFor="file-upload">
            <Button
              component="span"
              variant="contained"
              sx={{ marginBottom: "8px", mr: 2 }}
            >
              Upload Image
            </Button>
            <input
              type="file"
              id="file-upload"
              accept="image/*"
              className={classes.input}
              onChange={handleImageChange}
            />
          </label>
          <Button
            onClick={uploadFile}
            disabled={!file}
            sx={{ mb: "8px", mr: 1 }}
            variant="contained"
          >
            {t("save")}
          </Button>
        </Box>
      </Card>
    </>
  );
};

export default ImageUploader;
