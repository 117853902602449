import { StyledDataGrid } from "./StyledDataGrid";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from "@mui/icons-material";

const IwdDataGrid = ({
  columns,
  rows,
  page,
  setPage,
  perPage,
  count,
  height,
  sorts,
  setSorts,
  styles,
}) => {
  const [lastField, setLastField] = useState(null);

  const handleSortModelChange = (newModel) => {
    console.log("newama", newModel);
    if (newModel.length > 0) {
      const newSort = newModel[0];

      setSorts((prevSorts) => {
        const existingSortIndex = prevSorts.findIndex(
          (sort) => sort.field === newSort.field
        );

        if (existingSortIndex !== -1) {
          const updatedSorts = [...prevSorts];
          updatedSorts[existingSortIndex] = newSort;
          return updatedSorts;
        } else {
          return [...prevSorts, newSort];
        }
      });
    } else if (lastField) {
      setSorts((prevSorts) =>
        prevSorts.filter((sort) => sort.field !== lastField)
      );
      setLastField(null);
    }
    console.log("newModel", newModel);
  };

  const handleHeaderClick = (field) => {
    const isSorted = sorts.find((sort) => sort.field === field);
    if (!isSorted) {
      handleSortModelChange([{ field, sort: "asc" }]);
    } else if (isSorted.sort === "asc") {
      handleSortModelChange([{ field, sort: "desc" }]);
    } else {
      handleSortModelChange([]);
    }
  };

  const mappedColumns = columns.map((column) => ({
    ...column,
    renderHeader: (params) => (
      <span
        className="MuiDataGrid-columnHeaderTitle"
        onClick={() =>
          !column.nosort ? handleHeaderClick(params.field) : null
        }
        onMouseEnter={() => {
          setLastField(params?.field);
        }}
      >
        {params.colDef.headerName}
        {!column.nosort &&
          sorts.find((sort) => sort.field === params.field) && (
            <span>
              {sorts.find((sort) => sort.field === params.field).sort ===
              "asc" ? (
                <ArrowUpwardIcon />
              ) : (
                <ArrowDownwardIcon />
              )}
            </span>
          )}
      </span>
    ),
    sortable: false,
  }));

  return (
    <Box sx={{ height: height }}>
      <StyledDataGrid
        disableColumnMenu
        rows={rows ?? []}
        columns={mappedColumns}
        rowCount={count}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        getRowClassName={(params) => styles && styles(params)}
        onSortModelChange={handleSortModelChange}
        disableSelectionOnClick
        sortingMode="server"
        onPageChange={(pageNumber) => {
          setPage(pageNumber + 1);
        }}
        page={page - 1}
        paginationMode="server"
        getRowHeight={() => "auto"}
      />
    </Box>
  );
};

export default IwdDataGrid;
