import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const ApiError = ({ error, body }) => {
  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const processBody = (body) =>{
    if(body && body.errors){
      const errors = body.errors
      return Object.keys(errors).map(key => `${key}: ${errors[key].join(', ')}`).join('\n')
    }
    return ""
  }

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error.name}: {error.message} < br/>
          {processBody(body)}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ApiError;
