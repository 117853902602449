import { useState, useEffect } from "react";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import { useTranslation } from "react-i18next";
import { Grid, Box, Typography } from "@mui/material";
import { prepareBodyParams } from "../utils/api_params";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MetersTableTab from "../components/form/formComponents/MetersTableTab";
import { StyledDataGrid } from "./StyledDataGrid";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import { prepareBodyWithoutId } from "../utils/api_params";
import theme from "../theme";
import { prepareParams } from "../utils/api_params";
import Chip from "@mui/material/Chip";
import DraftsIcon from "@mui/icons-material/Drafts";
import IconButton from "@mui/material/IconButton";
import ModalDialog from "../components/meters/ModalDialog";
import MeterDatePicker from "../components/form/formComponents/MeterDatePicker";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import FormAccordion from "../components/form/formComponents/Accordion";
import { store } from "../redux/store";
import Button from "@mui/material/Button";
import IwdDataGrid from "./IwdDataGrid";
import { IwdAuthWrapper } from "../utils/IwdAuthWrapper";

const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties.map((element, i) => {
        if (i == 4) {
          return (
            <Grid key={element.id} alignItems="center" item xs={12}>
              {element.content}
            </Grid>
          );
        }
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const CondominiumConsumtionsTab = () => {
  const [getConsumptions, { status }] =
    store.useLazyGetCondominiumConsumptionsQuery();
  const condominiumName = useSelector(
    (state) => state?.condominiumSlice?.condominiumName
  );
  const [sorts, setSorts] = useState([]);
  const [meterType, setMeterType] = useState(["AFS"]);
  const [firstRender, setFirstRender] = useState(true);
  const is1280x720 = useMediaQuery(
    "(max-width: 1280px) and (max-height: 720px)"
  );
  const condominiumIdentifier = useSelector(
    (state) => state?.condominiumSlice?.condominiumIdentifier
  );
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const condomimiumConsumptions = useSelector(
    (state) => state?.condominiumSlice?.condominiumConsumptions
  );
  const condominiumConsumptionsCount = useSelector(
    (state) => state?.condominiumSlice?.condominiumConsumptionsCount
  );
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const [savedToDate, setSavedToDate] = useState(null);
  const [exportConsumptions] = store.useExportCondominiumConsumptionsMutation();
  const [savedPrevToDate, setSavedPrevToDate] = useState(null);
  const [savedFromDate, setSavedFromDate] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const dates = {
    from: new Date(),
    to: new Date(),
  };
  const newFrom = new Date(
    Date.UTC(
      dates.from.getFullYear(),
      dates.from.getMonth(),
      dates.from.getDate() - 7
    )
  );
  const newTo = new Date(
    Date.UTC(dates.to.getFullYear(), dates.to.getMonth(), dates.to.getDate())
  );
  const now = new Date();
  useEffect(() => {
    const prevDate = new Date(
      Date.UTC(
        dates.to.getFullYear(),
        dates.to.getMonth(),
        dates.to.getDate() - 2
      )
    );
    setSavedPrevToDate(prevDate.toISOString());
  }, []);
  const schema = {
    type: "object",
    properties: {
      serial_number: {
        id: 2,
        title: t("serial_number"),
        type: "string",
      },
      /*  condominium_name: {
        id: 4,
        title: t("condominium_name"),
        type: "string",
      }, */
      sub_condominium_name: {
        id: 5,
        title: t("sub_condominium_name"),
        type: "string",
      },
      apartment_name: {
        id: 1,
        title: t("apartment_name"),
        type: "string",
      },
      insertion_date: {
        id: 3,
        title: t("inserted_at"),
        type: "object",
        properties: {
          from: {
            type: "string",
            default: newFrom.toISOString(),
          },
          to: {
            type: "string",
            default: now.toISOString(),
          },
        },
      },
    },
  };
  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: t("search"),
      /* 
      props: {
        className: "bntListSearch",
      }, */
    },
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    insertion_date: {
      "ui:field": "datepicker",
    },
  };
  const fields = {
    datepicker: MeterDatePicker,
  };
  useEffect(() => {
    const testDate = new Date(
      Date.UTC(
        dates.to.getFullYear(),
        dates.to.getMonth(),
        dates.to.getDate() - 1
      )
    );
    console.log("prevsavedate", savedPrevToDate);
  }, [savedPrevToDate]);
  /*   const nowDate = new Date(
    Date.UTC(dates.to.getFullYear(), dates.to.getMonth(), dates.to.getDate())
  ); */
  useEffect(() => {
    if (condominiumIdentifier) {
      /*    const messagesToDate = new Date(
        Date.UTC(
          dates.to.getFullYear(),
          dates.to.getMonth(),
          dates.to.getDate() + 1
        )
      ); */
      /*    getConsumptions({
        condominium_id: condominiumIdentifier,
        from: savedFromDate ?? newFrom.toISOString(),
        page: page,
   
        to: savedToDate ?? messagesToDate.toISOString(),
        type: meterType,
      }); */
      setFirstRender(false);
    }
  }, [condominiumIdentifier, meterType]);
  const onSubmitClick = (obj) => {
    setSorts([]);
    setPage(1);
    const messagesDateTo = new Date(formData.insertion_date.to);
    const messageDateToPrev = new Date(formData.insertion_date.to);
    messageDateToPrev.setDate(messageDateToPrev.getDate() - 2);
    messagesDateTo.setDate(messagesDateTo.getDate() + 1);
    setSavedToDate(messagesDateTo.toISOString());
    setSavedFromDate(obj?.insertion_date?.from);
    setSavedPrevToDate(messageDateToPrev.toISOString());
    getConsumptions({
      ...obj,
      condominium_id: condominiumIdentifier,
      from: obj?.insertion_date?.from,
      page: 1,
      /* grouped: true, */
      to: messagesDateTo.toISOString(),
      type: meterType,
    });
  };
  useEffect(() => {
    if (!firstRender && status !== "pending") {
      const messagesDateTo = new Date(formData.insertion_date.to);
      const messageDateToPrev = new Date(formData.insertion_date.to);
      messageDateToPrev.setDate(messageDateToPrev.getDate() - 2);
      messagesDateTo.setDate(messagesDateTo.getDate() + 1);
      const messagesToDate = new Date(
        Date.UTC(
          dates.to.getFullYear(),
          dates.to.getMonth(),
          dates.to.getDate() + 1
        )
      );
      getConsumptions({
        condominium_id: condominiumIdentifier,
        from: formData?.insertion_date?.from,

        /* grouped: true, */
        to: savedToDate ?? messagesToDate.toISOString(),
        type: meterType,
        sorts: sorts,
      });
    }
  }, [sorts]);
  const resetFilter = () => {
    setSorts([]);
  };
  const bearer = localStorage.getItem("user_token");
  const onExportClick = (obj) => {
    const messagesDateTo = new Date(formData?.insertion_date?.to);
    const messageDateToPrev = new Date(formData.insertion_date.to);
    messageDateToPrev.setDate(messageDateToPrev.getDate() - 1);
    messagesDateTo.setDate(messagesDateTo.getDate() + 1);
    const from = obj?.insertion_date?.from;

    const params = {
      ...obj,
      condominium_id: condominiumIdentifier,
      from: from,
      to: messagesDateTo.toISOString(),
      /*     grouped: true, */
      type: meterType[0],
      sorts: sorts,
    };
    fetch(
      `${
        window.BASE_URL
      }/api/v1/plugin/condominium_consumption/${condominiumIdentifier}/export?${prepareParams(
        params
      )}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/vnd.api+json",
          Authorization: `Bearer ${bearer}`,
        },
        /*  body: JSON.stringify(prepareBodyWithoutId(params, "plugin")), */
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok, status: ${response.status}`
          );
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        console.log("blobik", blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${condominiumName}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
      })
      .then(() => {
        /*   setExportModal(false); */
        /*      dispatch(loadingOff()); */
      })
      .catch((error) => {
        console.error("Error during fetch:", error);
        /*    setExportModal(false); */
        /*  dispatch(setSeverity("error")); */
        /*     dispatch(loadingOff()); */
        /* dispatch(setMsg("Esportazione non è andata a buon fine")); */
      });
  };
  /*   useEffect(() => {
    if (!firstRender) {
      const messagesDateTo = new Date(formData.insertion_date.to);
      messagesDateTo.setDate(messagesDateTo.getDate() + 1);
      getConsumptions({
        ...formData,
        condominium_id: condominiumIdentifier,
        from: newFrom.toISOString(),
        grouped: true,
        to: messagesDateTo.toISOString(),
        type: meterType,
      });
    }
  }, [firstRender, meterType]); */
  const waterConsumptionsColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    /* {
      field: "condominium_name",
      headerName: t("condominium_name"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    }, */
    {
      field: "sub_condominium_name",
      headerName: t("sub_condominium_name"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "apartment_name",
      headerName: t("apartment_name"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "serial_number",
      headerName: t("serial_number"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },

    {
      field: "value",
      headerName: t("value"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "unit",
      headerName: t("unit"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "date",
      headerName: t("consum_date"),
      type: "string",
      editable: false,
      flex: 3,
      renderCell: (params) => {
        /*  console.log("sadasdasdasdasda", params.value);
        if (!params.value.warning) {
          return <Typography>{params?.value.date}</Typography>;
        } else {
          return (
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography>{params?.value.date}</Typography>
              <ReportProblemIcon
                sx={{ color: theme.palette.warning.main, ml: 2 }}
              />
            </Box>
          );
        } */
        return <Typography>{params?.value.date}</Typography>;
      },
    },
    {
      field: "reverse",
      headerName: t("reverse"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
      renderCell: (params) => {
        if (params?.value) {
          return <DoneIcon sx={{ color: theme.palette.success.main }} />;
        } else {
          return <CloseIcon sx={{ color: theme.palette.error.main }} />;
        }
      },
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      nosort: true,
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <IwdAuthWrapper
              children={
                <Link to={`/meters/${params.value}`}>
                  <VisibilityIcon
                    sx={{
                      float: "right",
                      mt: 1,
                      fill: theme.palette.primary.main,
                    }}
                  />
                </Link>
              }
              section={"block_show_btns"}
            />
          </Box>
        );
      },
    },
  ];

  const waterConsumptionsRows = condomimiumConsumptions?.map((item, i) => ({
    id: i,
    condominium_name: item?.meta?.condominium_name,
    sub_condominium_name: item?.meta?.sub_condominium_name,
    apartment_name: item?.meta?.apartment_name,
    serial_number: item?.meta?.serial_number,
    value:
      item.value !== null
        ? t("number", {
            val: item.value == "-0" ? "0" : item.value,
          })
        : "--",
    unit: item?.meta?.unit ? item?.meta?.unit : "--",
    date: {
      date: item?.date ? t("date_val", { val: new Date(item?.date) }) : "--",
      warning: savedPrevToDate > item.date ? true : false,
    },
    reverse: item?.meta?.reverse,
    actions: item?.device_id,
  }));
  const heatConsumptionsColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    /* {
      field: "condominium_name",
      headerName: t("condominium_name"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    }, */
    {
      field: "sub_condominium_name",
      headerName: t("sub_condominium_name"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "apartment_name",
      headerName: t("apartment_name"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "serial_number",
      headerName: t("serial_number"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },

    {
      field: "heat_value",
      headerName: t("last_reading_heat_val"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "cold_value",
      headerName: t("last_reading_cold_val"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "unit",
      headerName: t("unit"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "date",
      headerName: t("consum_date"),
      type: "string",
      editable: false,
      flex: 3,
      renderCell: (params) => {
        /*  console.log("sadasdasdasdasda", params.value);
        if (!params.value.warning) {
          return <Typography>{params?.value.date}</Typography>;
        } else {
          return (
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography>{params?.value.date}</Typography>
              <ReportProblemIcon
                sx={{ color: theme.palette.warning.main, ml: 2 }}
              />
            </Box>
          );
        } */
        return <Typography>{params?.value.date}</Typography>;
      },
    },
    {
      field: "reverse",
      headerName: t("reverse"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
      renderCell: (params) => {
        if (params?.value) {
          return <DoneIcon sx={{ color: theme.palette.success.main }} />;
        } else {
          return <CloseIcon sx={{ color: theme.palette.error.main }} />;
        }
      },
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      nosort: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <IwdAuthWrapper
              children={
                <Link to={`/meters/${params.value}`}>
                  <VisibilityIcon
                    sx={{
                      float: "right",
                      mt: 1,
                      fill: theme.palette.primary.main,
                    }}
                  />
                </Link>
              }
              section={"block_show_btns"}
            />
          </Box>
        );
      },
    },
  ];
  const heatConsumptionsRows = condomimiumConsumptions?.map((item, i) => ({
    id: i,
    condominium_name: item?.meta?.condominium_name,
    sub_condominium_name: item?.meta?.sub_condominium_name,
    apartment_name: item?.meta?.apartment_name,
    serial_number: item?.meta?.serial_number,
    cold_value:
      item?.cold_value !== null
        ? item?.meta?.unit === "Wh"
          ? t("number", {
              val: item?.cold_value / 1000,
            })
          : t("number", {
              val: item?.cold_value,
            })
        : "--",
    heat_value:
      item?.heat_value !== null
        ? item?.meta?.unit === "Wh"
          ? t("number", {
              val: item?.heat_value / 1000,
            })
          : t("number", {
              val: item?.heat_value,
            })
        : "--",
    unit: item?.meta?.unit === "Wh" ? "kWh" : item?.meta?.unit ?? "--",
    date: {
      date: item?.date ? t("date_val", { val: new Date(item?.date) }) : "--",
      warning: savedPrevToDate > item.date ? true : false,
    },
    reverse: item?.meta?.reverse,
    actions: item?.device_id,
  }));
  useEffect(() => {
    console.log("pisya", condomimiumConsumptions);
  }, [condomimiumConsumptions]);
  const allocatorConsumptionsColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    /*  {
      field: "condominium_name",
      headerName: t("condominium_name"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    }, */
    {
      field: "sub_condominium_name",
      headerName: t("sub_condominium_name"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "apartment_name",
      headerName: t("apartment_name"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "serial_number",
      headerName: t("serial_number"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },

    {
      field: "value_uncoverted",
      headerName: t("last_unconverted_reading"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "k",
      headerName: t("K"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    {
      field: "value_converted",
      headerName: t("parameterized_reading"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    },
    /*  {
      field: "unit",
      headerName: t("unit"),
      type: "string",

      editable: false,
      flex: 2,
      editable: false,
    }, */
    {
      field: "date",
      headerName: t("consum_date"),
      type: "string",
      editable: false,
      flex: 2,
      renderCell: (params) => {
        /*  console.log("sadasdasdasdasda", params.value);
        if (!params.value.warning) {
          return <Typography>{params?.value.date}</Typography>;
        } else {
          return (
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography>{params?.value.date}</Typography>
              <ReportProblemIcon
                sx={{ color: theme.palette.warning.main, ml: 2 }}
              />
            </Box>
          );
        } */
        return <Typography>{params?.value.date}</Typography>;
      },
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      nosort: true,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <IwdAuthWrapper
              children={
                <Link to={`/meters/${params.value}`}>
                  <VisibilityIcon
                    sx={{
                      float: "right",
                      mt: 1,
                      fill: theme.palette.primary.main,
                    }}
                  />
                </Link>
              }
              section={"block_show_btns"}
            />
          </Box>
        );
      },
    },
  ];
  const allocatorConsumptionsRows = condomimiumConsumptions?.map((item, i) => ({
    id: i,
    condominium_name: item?.meta?.condominium_name,
    sub_condominium_name: item?.meta?.sub_condominium_name,
    apartment_name: item?.meta?.apartment_name,
    serial_number: item?.meta?.serial_number,
    value_converted:
      item?.value_converted && item?.value_converted !== null
        ? t("number", {
            val: item?.value_converted,
          })
        : "--",
    value_uncoverted:
      item?.value_uncoverted !== null
        ? t("number", {
            val: item?.value_uncoverted,
          })
        : "--",
    /*   unit: item.attributes?.values?.values.value?.last_reading?.unit ?? "--", */
    k:
      item?.meta?.k !== null
        ? t("number", {
            val: item?.meta?.k,
          })
        : "--",
    unit: item?.meta?.unit === "Wh" ? "kWh" : item?.meta?.unit ?? "--",
    date: {
      date: item?.date ? t("date_val", { val: new Date(item?.date) }) : "--",
      warning: savedPrevToDate > item.date ? true : false,
    },
    actions: item?.device_id,
  }));
  const waterSum = condomimiumConsumptions?.reduce((acc, obj) => {
    if (obj?.value !== null && !isNaN(obj?.value)) {
      return acc + obj?.value;
    } else {
      return acc;
    }
  }, 0);
  const coldSum = condomimiumConsumptions?.reduce((acc, obj) => {
    if (obj?.cold_value !== null && !isNaN(obj?.cold_value)) {
      return acc + obj?.cold_value;
    } else {
      return acc;
    }
  }, 0);
  const heatSum = condomimiumConsumptions?.reduce((acc, obj) => {
    if (obj?.heat_value !== null && !isNaN(obj?.heat_value)) {
      return acc + obj?.heat_value;
    } else {
      return acc;
    }
  }, 0);
  const kSum = condomimiumConsumptions?.reduce((acc, obj) => {
    if (obj?.meta?.k !== null && !isNaN(obj?.meta?.k)) {
      return acc + obj?.meta?.k;
    } else {
      return acc;
    }
  }, 0);
  const convertedSum = condomimiumConsumptions?.reduce((acc, obj) => {
    if (obj?.value_converted !== null && !isNaN(obj?.value_converted)) {
      return acc + obj?.value_converted;
    } else {
      return acc;
    }
  }, 0);
  const unconvertedSum = condomimiumConsumptions?.reduce((acc, obj) => {
    if (obj?.value_uncoverted !== null && !isNaN(obj?.value_uncoverted)) {
      return acc + obj?.value_uncoverted;
    } else {
      return acc;
    }
  }, 0);
  const dinamicWidthSelector = () => {
    if (is1280x720 && appBarIsOpen) {
      return "62vw";
    }
    if (is1280x720 && !appBarIsOpen) {
      return "78vw";
    }
    if (!is1280x720 && appBarIsOpen) {
      return "67vw";
    }
    if (!is1280x720 && !appBarIsOpen) {
      return "79vw";
    }
  };
  useEffect(() => {
    console.log("condominiumConsumptionsCount", condominiumConsumptionsCount);
  }, [condominiumConsumptionsCount]);
  return (
    <>
      <FormAccordion width={() => dinamicWidthSelector()}>
        <Form
          schema={schema}
          fields={fields}
          formData={formData}
          /*   customValidate={customValidate} */
          showErrorList={false}
          onChange={(changeEvent) => setFormData(changeEvent.formData)}
          onSubmit={(values) => onSubmitClick(values.formData)}
          validator={validator}
          uiSchema={uiSchema}
          children={true}
        >
          <Box>
            <Button
              onClick={() => onExportClick(formData)}
              variant="contained"
              sx={{ float: "right", mr: 2, mt: 2 }}
            >
              {t("export")}
            </Button>
            <Button
              variant="contained"
              sx={{ float: "right", mt: 2 }}
              onClick={() => onSubmitClick(formData)}
            >
              {t("search")}
            </Button>
          </Box>
        </Form>
      </FormAccordion>

      <Box sx={{ height: 400, mt: 2, width: dinamicWidthSelector() }}>
        <MetersTableTab
          resetFilter={resetFilter}
          afs={
            <Box sx={{ height: 400 }}>
              <Typography
                sx={{ mb: 1, color: theme.palette.primary.main }}
                align="end"
              >{`${t("total")} : ${t("number", {
                val: waterSum,
              })} m³`}</Typography>
              {/*     <StyledDataGrid
                disableColumnMenu
                rows={waterConsumptionsRows ?? []}
                onSortModelChange={(model) => console.log("test")}
                columns={waterConsumptionsColumns}
                pageSize={perPage}
                rowsPerPageOptions={[perPage]}
                onPageChange={(pageNumber) => {
                  setPage(pageNumber + 1);
                }}
                page={page - 1}
                rowCount={condominiumConsumptionsCount}
                paginationMode="client"
                getRowHeight={() => "auto"}
              /> */}
              <IwdDataGrid
                columns={waterConsumptionsColumns}
                perPage={perPage}
                setPage={setPage}
                rows={waterConsumptionsRows}
                page={page}
                count={condominiumConsumptionsCount}
                setSorts={setSorts}
                sorts={sorts}
                height={600}
              />
            </Box>
          }
          heatMeter={
            <Box sx={{ height: 400 }}>
              <Typography
                sx={{ mb: 1, color: theme.palette.primary.main }}
                align="end"
              >{`${t("total")} ${t("parameterized_reading")} : ${t("number", {
                val: convertedSum,
              })} `}</Typography>
              <Typography
                sx={{ mb: 1, color: theme.palette.primary.main }}
                align="end"
              >{`${t("total")} ${t("last_unconverted_reading")} : ${t(
                "number",
                {
                  val: unconvertedSum,
                }
              )}`}</Typography>
              {/*    <Typography
                sx={{ mb: 1, color: theme.palette.primary.main }}
                align="end"
              >{`K : ${t("number", {
                val: kSum,
              })}`}</Typography> */}

              <IwdDataGrid
                columns={allocatorConsumptionsColumns}
                perPage={perPage}
                setPage={setPage}
                rows={allocatorConsumptionsRows}
                page={page}
                count={condominiumConsumptionsCount}
                setSorts={setSorts}
                sorts={sorts}
                height={600}
              />
            </Box>
          }
          colorMeter={
            <Box sx={{ height: 400 }}>
              <Typography
                sx={{ mb: 1, color: theme.palette.primary.main }}
                align="end"
              >{`${t("total_hot")} : ${t("number", {
                val: coldSum / 1000,
              })} kWh`}</Typography>
              <Typography
                sx={{ mb: 1, color: theme.palette.primary.main }}
                align="end"
              >{`${t("total_cold")} : ${t("number", {
                val: heatSum / 1000,
              })} kWh`}</Typography>
              {/*  <StyledDataGrid
                disableColumnMenu
                rows={heatConsumptionsRows ?? []}
                onSortModelChange={(model) => console.log("test")}
                columns={heatConsumptionsColumns}
                pageSize={perPage}
                rowsPerPageOptions={[perPage]}
                onPageChange={(pageNumber) => {
                  setPage(pageNumber + 1);
                }}
                page={page - 1}
                rowCount={condominiumConsumptionsCount}
                
                paginationMode="client"
                getRowHeight={() => "auto"}
              /> */}
              <IwdDataGrid
                columns={heatConsumptionsColumns}
                perPage={perPage}
                setPage={setPage}
                rows={heatConsumptionsRows}
                page={page}
                count={condominiumConsumptionsCount}
                setSorts={setSorts}
                sorts={sorts}
                height={600}
              />
            </Box>
          }
          setMeterType={setMeterType}
        />
      </Box>
    </>
  );
};
export default CondominiumConsumtionsTab;
