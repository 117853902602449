import { createSlice } from "@reduxjs/toolkit";
import { store } from "../store";

const initialState = {
  userGroups: null,
  count: null,
  userGroup: null,
  userGroupTrashId: null,
  blockGroups: null,
  blockGroupsCount: null,
  blockGroupsTrashId: null,
  usersGroups: null,
  usersGroupsCount: null,
  usersGroupsTrashId: null,
};

const userGroupSlice = createSlice({
  name: "userGroupSlice",
  initialState,
  reducers: {
    cleanUserGroup(state) {
      state.userGroup = null;
    },
    setUsergroupTrashId(state, action) {
      state.userGroupTrashId = action.payload;
    },
    setBlockGroupsTrashId(state, action) {
      state.blockGroupsTrashId = action.payload;
    },
    setUsersGroupsTrashId(state, action) {
      state.usersGroupsTrashId = action.payload;
    },
    resetBlockGroupsData(state) {
      state.blockGroups = null;
      state.blockGroupsCount = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.userGroupEndpoints.getUserGroups.matchFulfilled,
      (state, action) => {
        state.userGroups = action.payload.data;
        state.count = action.payload.meta.count;
      }
    );
    builder.addMatcher(
      store.userGroupEndpoints.getUserGroup.matchFulfilled,
      (state, action) => {
        state.userGroup = action.payload.data.attributes;
      }
    );
    builder.addMatcher(
      store.userGroupEndpoints.getBlockGroups.matchFulfilled,
      (state, action) => {
        state.blockGroups = action.payload.data;
        state.blockGroupsCount = action.payload.meta.count;
      }
    );
    builder.addMatcher(
      store.userGroupEndpoints.getIndexUserGroups.matchFulfilled,
      (state, action) => {
        state.usersGroups = action.payload.data;
        state.usersGroupsCount = action.payload.count;
      }
    );
  },
});
export const {
  cleanUserGroup,
  setUsergroupTrashId,
  setBlockGroupsTrashId,
  setUsersGroupsTrashId,
  resetBlockGroupsData,
} = userGroupSlice.actions;
export default userGroupSlice.reducer;
