import * as React from "react";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Box from "@material-ui/core";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/en";
import "dayjs/locale/it";

const BalanceMonthDatePicker = ({ props }) => {
  const currentDate = new Date(); // Текущая дата

  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  );

  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    0
  );
  lastDayOfMonth.setUTCHours(23, 59, 59, 999);

  console.log("firstDayOfMonth:", firstDayOfMonth.toISOString());
  console.log("lastDayOfMonth:", lastDayOfMonth.toISOString());

  useEffect(() => {
    if (props?.schema?.mode == "month") {
      debugger;
      props.onChange({
        from: firstDayOfMonth.toISOString(),
        to: lastDayOfMonth?.toISOString(),
      });
    }
  }, [props?.schema?.mode]);

  const [startDate, setStartDate] = useState(firstDayOfMonth);

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  let EndDateerrorMessage = props?.errorSchema?.to?.__errors[0];
  let StartDateErrorMessage = props?.errorSchema?.from?.__errors[0];
  const newFrom = new Date(startDate);

  const invalidStartDate = newFrom == "Invalid Date" ? "Invalid Date" : null;

  useEffect(() => {
    if (newFrom != "Invalid Date" && startDate !== null) {
      const start = new Date(startDate);
      const from = new Date(
        Date.UTC(
          start.getFullYear(),
          start.getMonth(),
          start.getDate(),
          start.getHours(),
          start.getMinutes(),
          start.getSeconds()
        )
      );
      from.setUTCHours(0, 0, 0, 0);
      const isoStart = from.toISOString();
      const lastDayOfMonth = new Date(from.getFullYear(), from.getMonth() + 1);
      lastDayOfMonth.setUTCHours(23, 59, 59, 59);
      const isoEnd = lastDayOfMonth.toISOString();
      console.log("fromik", startDate);
      props.onChange({
        from: isoStart,
        to: isoEnd,
      });
    }
  }, [startDate]);

  useEffect(() => {
    console.log("doradura", startDate);
  }, [startDate]);

  return (
    <Grid container>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={currentLanguage.split("-")[0]}
      >
        <Grid item xs={12}>
          <DatePicker
            renderInput={(props) => (
              <TextField
                sx={{ width: "99%" }}
                {...props}
                error={
                  StartDateErrorMessage
                    ? StartDateErrorMessage
                    : invalidStartDate
                }
                helperText={
                  StartDateErrorMessage
                    ? StartDateErrorMessage
                    : invalidStartDate
                }
              />
            )}
            label={t("month")}
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
            views={["year", "month"]}
          />
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
};

export default BalanceMonthDatePicker;
