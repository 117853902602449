import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { store } from "../../redux/store";
import { Typography } from "@mui/material";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { worklistActions } from "../../redux/slices/worklistSlice";
import { setMessage } from "../../redux/slices/snackbarSlice";
import theme from "../../theme";
import { useEffect, useState } from "react";
const WorklistIndex = () => {
  const users = useSelector((state) => state?.userSlice?.users);
  const worklists = useSelector((state) => state?.worklistSlice?.worklistIndex);
  const worklistTashId = useSelector(
    (state) => state?.worklistSlice?.worklistTashId
  );
  const [trashModal, setTrashModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const worklistsCount = useSelector(
    (state) => state?.worklistSlice?.worklistIndexCount
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [getWorklists] = store.useLazyGetWorklistIndexQuery();
  const [page, setPage] = useState(1);
  const [deleteWorklist, { isSuccess: deleteSuccess }] =
    store.useDeleteWorklistMutation();
  const [getUsers] = store.useLazyGetUsersQuery();
  const [perPage, setPerPage] = useState(10);
  const navigate = useNavigate();
  useEffect(() => {
    if (!users) {
      getUsers();
    }
  }, [users]);

  useEffect(() => {
    getWorklists({ page: page, per_page: perPage });
  }, [page]);
  const onTrashClick = (id) => {
    setIdToDelete(id);
    setTrashModal(true);
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      /*  hide: true, */
    },
    {
      field: "name",
      headerName: t("name"),
      width: 90,
      sortable: false,
      flex: 1,
      /*  hide: true, */
    },
    {
      field: "editor",
      headerName: t("worklisteditor"),
      sortable: false,
      editable: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => navigate(`/worklisteditors/${params.value}`)}
          >
            <VisibilityIcon sx={{ fill: theme.palette.primary.main }} />
          </IconButton>
        );
      },
    },
    {
      field: "user",
      headerName: t("user"),
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        const user = users?.filter((user) => user?.id == params?.value)[0];
        return (
          <Typography>{`${user?.attributes?.first_name} ${user?.attributes?.last_name}`}</Typography>
        );
      },
    },

    {
      field: "start_date",
      headerName: t("start_date"),
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "end_date",
      headerName: t("end_date"),
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,

      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <IconButton
              sx={{ float: "right" }}
              onClick={() => onTrashClick(params.value)}
            >
              <DeleteIcon sx={{ ml: 1, fill: theme.palette.error.main }} />
            </IconButton>

            <Link to={`/worklists/${params.value}`}>
              <VisibilityIcon
                sx={{
                  float: "right",
                  mt: 1,
                  fill: theme.palette.primary.main,
                }}
              />
            </Link>
          </Box>
        );
      },
    },
  ];

  const rows = worklists?.map((wl) => ({
    id: wl?.id,
    name: wl?.name,
    editor: wl.work_list_editor_id,
    user: wl?.user_id,
    start_date: t("dt", { val: new Date(wl.start_date) }),
    end_date: t("dt", { val: new Date(wl.end_date) }),
    actions: wl?.id,
  }));
  useEffect(() => {
    console.log("wlst", worklists);
  }, [worklists]);
  useEffect(() => {
    if (worklistTashId) {
      debugger;
      console.log("nahueeeeeeeee");
      deleteWorklist(worklistTashId);
      dispatch(worklistActions.setWorklistTrashId(null));
    }
  }, [worklistTashId]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("delete_success")));
      getWorklists({ page: page, per_page: perPage });
    }
  }, [deleteSuccess]);
  return (
    <>
      <Box sx={{ height: "68vh", mt: 4 }}>
        <Typography
          variant="h1"
          sx={{ mb: 2, color: theme.palette.primary.main }}
        >
          {t("worklists")}
        </Typography>
        <DeleteConfirmModal
          trashModal={trashModal}
          setTrashModal={setTrashModal}
          text={t("worklist_delete_text")}
          title={t("worklist_delete_title")}
          deleteFunc={() => worklistActions.setWorklistTrashId(idToDelete)}
        />
        <StyledDataGrid
          disableColumnMenu
          rows={rows ?? []}
          columns={columns ?? []}
          rowCount={worklistsCount}
          pageSize={perPage}
          rowsPerPageOptions={[perPage]}
          disableSelectionOnClick
          onPageChange={(pageNumber) => {
            setPage(pageNumber + 1);
          }}
          page={page - 1}
          // loading={isLoadingTable}
          paginationMode="server"
          getRowHeight={() => "auto"}
        />
      </Box>
    </>
  );
};

export default WorklistIndex;
