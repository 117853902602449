import React, { useState, useEffect } from "react";
import { Select, MenuItem } from "@mui/material";
import { useParams } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { store } from "../../redux/store";

function MonthSelect() {
  const [selectedMonth, setSelectedMonth] = useState("");
  const [defaultMonth, setDefaultMonth] = useState("");
  const [firstRender, setFirstRender] = useState(true);
  const months = [];
  const currentDate = new Date();

  const { appId, meterId } = useParams();

  useEffect(() => {
    setDefaultMonth(
      `${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`
    );
    setSelectedMonth(
      `${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`
    );
  }, []);

  // Добавляем текущий месяц
  months.push({
    value: `${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`,
    label: ` ${currentDate.toLocaleString("default", {
      month: "long",
    })} ${currentDate.getFullYear()}`,
  });

  // Добавляем предыдущие 12 месяцев
  for (let i = 1; i <= 13; i++) {
    const date = new Date();
    date.setMonth(currentDate.getMonth() - i);
    months.push({
      value: `${date.getMonth() + 1}-${date.getFullYear()}`,
      label: `${date.toLocaleString("default", {
        month: "long",
      })} ${date.getFullYear()}`,
    });
  }

  const handleSearchClick = () => {
    if (selectedMonth) {
      const [month, year] = selectedMonth.split("-");
      const fromDate = new Date(year, month - 1, 1); // Последний день предыдущего месяца
      const toDate = new Date(year, month, 1); // Первый день следующего месяца // Отнимаем один день, чтобы получить последний день текущего месяца
      /* toDate.setDate(toDate.getDate() - 1) */ fromDate.setHours(0, 0, 0, 0); // Установка времени на 00:00:00
      toDate.setHours(23, 59, 59, 59); // Установка времени на 23:59:59

      console.log("from_date:", fromDate.toISOString());
      console.log("to_date:", toDate.toISOString());
    }
  };
  useEffect(() => {
    if (firstRender && selectedMonth) {
      handleSearchClick();
      setFirstRender(false);
    }
  }, [selectedMonth]);

  return (
    <Box sx={{ mt: 2, mb: 7 }}>
      <Select
        value={selectedMonth}
        onChange={(e) => setSelectedMonth(e.target.value)}
        fullWidth
        defaultValue={defaultMonth}
      >
        {months.map((month, index) => (
          <MenuItem key={index} value={month.value}>
            {month.label}
          </MenuItem>
        ))}
      </Select>
      <Button
        variant="contained"
        sx={{ mt: 2, float: "right" }}
        onClick={handleSearchClick}
      >
        Search
      </Button>
    </Box>
  );
}

export default MonthSelect;
