import {
  PieChart,
  Tooltip as PieTooltip,
  Cell,
  LineChart,
  XAxis,
  YAxis,
  Line,
  CartesianGrid,
} from "recharts";
import { Pie } from "recharts";
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import PopoverDashboardCardError from "../Popover";
import { deleteCreatedWidget } from "../../../redux/slices/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  selectColor,
  selectTextColor,
  selectLabel,
} from "./utils/widgetConfigurations";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, Typography, useTheme, Paper, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Chip, Divider } from "@material-ui/core";
import { useEffect, useState } from "react";
import PopoverCardTitle from "../PopoverCardTitle";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import { setDragBlcok } from "../../../redux/slices/dashboardSlice";
import NetworkCellIcon from "@mui/icons-material/NetworkCell";
import GroupIcon from "@mui/icons-material/Group";
const COLORS = [
  "#408fda",
  "#73b0ed",
  "#c1deff",
  "#aeefc8",
  /* "#86deaa",
  "#73c897",
  "#9180cc",
  "#c1aff6",
  "#cadad8",
  "#9bb4b1",
  "#7ea4a8", */
];
const data01 = [
  {
    name: "Group A",
    value: 400,
  },
  {
    name: "Group B",
    value: 300,
  },
  {
    name: "Group C",
    value: 300,
  },
  {
    name: "Group D",
    value: 200,
  },
  {
    name: "Group E",
    value: 278,
  },
  {
    name: "Group F",
    value: 189,
  },
];

const LineCharWidget = ({
  widget,
  edit,
  setTrashModal,
  setTrashId,
  creationPhase,
}) => {
  const previewMode = useSelector((state) => state?.widgetsSlice?.previewMode);
  const [linkUnderline, setLinkUnderline] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const data =
    widget?.last_update?.data == "NA"
      ? []
      : widget?.last_update?.data?.map((item) => ({
          name: item.name,
          value: item.value,
        }));
  const onTrashClick = (id) => {
    setTrashModal(true);
    setTrashId(id);
  };
  const onDelete = (id) => {
    dispatch(deleteCreatedWidget(id));
  };
  useEffect(() => {});
  return (
    <Paper
      elevation={5}
      sx={{
        maxWidth: !previewMode ? 400 : 700,
        height: 285,
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ m: 1 }}>
        <PopoverCardTitle
          title={widget?.name}
          description={widget?.description}
        />
        <Divider />
        {edit && (
          <IconButton sx={{ float: "right" }}>
            <DeleteIcon
              onMouseEnter={() => dispatch(setDragBlcok(true))}
              onMouseLeave={() => dispatch(setDragBlcok(false))}
              onClick={
                creationPhase
                  ? () => onDelete(widget?.id)
                  : () => onTrashClick(widget?.id)
              }
              sx={{
                float: "right",
                fill: theme.palette.error.main,
                cursor: "pointer",
              }}
            />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <>
          {widget.link && data.length > 0 && (
            <a
              onMouseEnter={() => setLinkUnderline(true)}
              onMouseLeave={() => setLinkUnderline(false)}
              style={{
                textDecoration: linkUnderline ? "" : "none",
                color: theme.palette.primary.main,
                marginLeft: 0,
              }}
              href={widget?.link}
              target="_blank"
            >
              <LineChart
                width={250}
                height={150}
                margin={{
                  left: 0,
                  top: 10,
                  right: 0,
                }}
                data={data}
              >
                <PieTooltip />
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" interval="preserveStartEnd" />
                <YAxis />

                <Line dataKey="value" stroke="#8884d8" />
              </LineChart>
            </a>
          )}
          {!previewMode &&
            (!widget.link && data.length > 0 ? (
              <LineChart
                width={250}
                height={150}
                margin={{
                  left: 0,
                  top: 10,
                  right: 30,
                }}
                data={data}
              >
                <PieTooltip />
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" interval="preserveStartEnd" />
                <YAxis />

                <Line dataKey="value" stroke="#8884d8" />
              </LineChart>
            ) : (
              <Typography variant="h1" color={theme.palette.primary.main}>
                NA
              </Typography>
            ))}
          {previewMode && (
            <LineChart
              width={450}
              height={150}
              margin={{
                left: 0,
                top: 10,
                right: 30,
              }}
              data={data01}
            >
              <PieTooltip />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" interval="preserveStartEnd" />
              <YAxis />

              <Line dataKey="value" stroke="#8884d8" />
            </LineChart>
          )}
        </>
        {widget?.last_error?.date && !previewMode && (
          <PopoverDashboardCardError error={widget?.last_error} />
        )}
      </Box>
      <Box sx={{ m: 1 }}>
        <Grid
          container
          alignItems="center"
          rowSpacing={1}
          direction="row"
          spacing={1}
          xs={12}
        >
          {widget?.link ? (
            <>
              <Grid item xs={2}>
                {widget?.tags?.map((item) => (
                  <Chip
                    style={{
                      backgroundColor:
                        /* selectColor(widget?.tags?.[0], theme), */ theme
                          .palette.secondary.main,
                      color: selectTextColor(widget?.tags?.[0], theme),
                    }}
                    label={selectLabel(widget?.tags?.[0], theme)}
                  />
                ))}
              </Grid>
              <Grid item xs={2}>
                {widget?.link && (
                  <IconButton href={widget?.link} target="_blank">
                    <BookmarksIcon
                      sx={{ ml: 1, fill: theme.palette.primary.main }}
                      /* sx={{ fill: selectColor(widget?.tags?.[0]) }} */
                    />
                  </IconButton>
                )}
              </Grid>
            </>
          ) : (
            <Grid item xs={4}>
              {widget?.tags?.map((item) => (
                <Chip
                  style={{
                    backgroundColor:
                      /* selectColor(widget?.tags?.[0], theme), */ theme.palette
                        .primary.main,
                    color: selectTextColor(widget?.tags?.[0], theme),
                  }}
                  label={selectLabel(widget?.tags?.[0], theme)}
                />
              ))}
            </Grid>
          )}

          <Grid item xs={8}>
            <Tooltip title={t("last_update")}>
              <Typography
                textAlign="end"
                variant="body2"
                sx={{ fontSize: "0.75rem" }}
              >
                {t("dt", { val: new Date(widget?.last_update?.date) })}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default LineCharWidget;
