import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import React from "react";
import WorklistPolygonList from "./WorklistPolygonList";
import Button from "@mui/material/Button";
import WorkListClassSelector from "./WorkListClassSelector";
import {
  useCreateWorklistEditorMutation,
  useCreateWorklistPolygonMutation,
} from "../../redux/slices/worklistApi";
import { store } from "../../redux/store";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { useUpdateWorklistEditorMutation } from "../../redux/slices/worklistApi";
import { useEffect, useState } from "react";
import { useDeleteWorklistPolygonMutation } from "../../redux/slices/worklistApi";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { useLazyGetWorklistEditorShowQuery } from "../../redux/slices/worklistApi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import theme from "../../theme";
import { useTranslation } from "react-i18next";
import { worklistActions } from "../../redux/slices/worklistSlice";
const objectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={3}>
      {props.properties.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={12}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const WorklistEditorShowForm = ({ setShowMode, showMode }) => {
  const preparePolygonBody = ({ positions, id }) => {
    return {
      data: {
        attributes: {
          geom: {
            coordinates: [[...positions, positions[0]]],
            type: "Polygon",
          },
        },
        work_list_editor_id: id,
        type: "plguin",
      },
    };
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const polygons = useSelector(
    (state) => state?.worklistSlice?.newPolygonsInUpdate
  );
  const polygonsToDelete = useSelector(
    (state) => state?.worklistSlice?.polygonsToRemove
  )?.map((item) => item?.polygon?.geom?.id);
  const worklistEditor = useSelector(
    (state) => state?.worklistSlice?.worklistEditor
  );
  const [getWorklistEditor] = store.useLazyGetWorklistEditorShowQuery();
  const [updateWorlistEditor, { isSuccess: updSuccess }] =
    store.useUpdateWorklistEditorMutation();
  const [deletePolygon, { isSuccess: deleteSuccess }] =
    store.useDeleteWorklistPolygonMutation();
  const [createPolygon, { isSuccess: createSuccess }] =
    store.useCreateWorklistPolygonMutation();
  const [worklistId, setWorklistId] = useState(null);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});

  /*     const onSubmitClick = () => {
    const attributes = {
      name: formData.name,
      description: formData.description,
      classes: [...formData.class],
    };
    createWorklistEditor(attributes)
      .unwrap()
      .then((data) => setWorklistId(+data.data.attributes?.id))
      .catch((er) => console.log("err"));
  }; */
  useEffect(() => {
    if (updSuccess && polygons.length > 0) {
      for (let i = 0, len = polygons.length; i < len; i++) {
        createPolygon({ attributes: polygons[i], id: id });
      }
    }
  }, [updSuccess, polygons]);
  useEffect(() => {
    if (updSuccess && polygonsToDelete.length > 0) {
      for (let i = 0; i < polygonsToDelete.length; i++) {
        deletePolygon(polygonsToDelete[i]);
      }
    }
  }, [updSuccess]);
  useEffect(() => {
    dispatch(worklistActions.updatingOn());
  }, []);
  useEffect(() => {
    getWorklistEditor(id);
  }, []);
  useEffect(() => {
    if (worklistEditor) {
      if (worklistEditor?.devices) {
        setShowMode("table");
      } else {
        setShowMode("map");
      }
    }
  }, [worklistEditor]);
  useEffect(() => {
    console.log("worklistId", worklistId);
  }, [worklistId]);

  const schema = {
    title: "Device Group form",
    type: "object",
    properties: {
      name: {
        id: 0,
        type: "string",
        title: t("name"),
        default: worklistEditor?.name,
      },
      description: {
        id: 0,
        type: "string",
        title: t("description"),
        default: worklistEditor?.description,
      },
      class: {
        id: 0,
        type: "string",
        title: t("tipologia"),
        multiple: true,
        url: "altior/digital_twin",
        type: "string",
        default: worklistEditor?.classes,
      },
    },
  };
  const fileSchema = {
    title: "Device Group form",
    type: "object",
    properties: {
      name: {
        id: 0,
        type: "string",
        title: t("name"),
        default: worklistEditor?.name,
      },
      description: {
        id: 0,
        type: "string",
        title: t("description"),
        default: worklistEditor?.description,
      },
    },
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": objectFieldTemplate,

    "ui:submitButtonOptions": {
      submitText: t("create"),
    },
    description: {
      "ui:options": {
        widget: "textarea",
        minRows: 15,
      },
    },
    class: {
      "ui:field": "search",
    },
  };
  const fields = {
    search: WorkListClassSelector,
  };
  useEffect(() => {
    console.log("polygonsToDelete", polygonsToDelete);
  }, [polygonsToDelete]);
  useEffect(() => {
    if (updSuccess && polygonsToDelete.length == 0 && polygons.length == 0) {
      dispatch(setMessage(t("successfully_updated")));
      getWorklistEditor(id);
      dispatch(worklistActions.resetPolygons());
    }
    if (updSuccess && deleteSuccess && createSuccess) {
      dispatch(setMessage(t("successfully_updated")));
      getWorklistEditor(id);
      dispatch(worklistActions.resetPolygons());
    }
    if (updSuccess && deleteSuccess && polygons.length == 0) {
      dispatch(setMessage(t("successfully_updated")));
      getWorklistEditor(id);
      dispatch(worklistActions.resetPolygons());
    }
    if (updSuccess && createSuccess && polygonsToDelete.length == 0) {
      dispatch(setMessage(t("successfully_updated")));
      getWorklistEditor(id);
      dispatch(worklistActions.resetPolygons());
    }
  }, [updSuccess, createSuccess, deleteSuccess]);
  const onSubmitClick = () => {
    const attributes = {
      name: formData.name,
      description: formData.description,
      classes: formData.class,
    };
    updateWorlistEditor({ attributes: attributes, id: id });
  };
  return (
    <>
      {showMode && (
        <>
          <Form
            schema={showMode == "map" ? schema : fileSchema}
            fields={fields}
            formData={formData}
            /*     customValidate={customValidate} */
            showErrorList={false}
            children={true}
            /* onSubmit={onSubmitClick} */
            onChange={(changeEvent) => setFormData(changeEvent.formData)}
            /*   onSubmit={(values) => {
      onSubmitClick(values.formData);
    }} */
            validator={validator}
            uiSchema={uiSchema}
          />
          <WorklistPolygonList />
          <Button
            onClick={onSubmitClick}
            variant="outlined"
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: "white",
              mt: 2,
              "&:hover": { backgroundColor: theme.palette.primary.main },
            }}
          >
            {t("update")}
          </Button>
        </>
      )}
    </>
  );
};
export default WorklistEditorShowForm;
