import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import InputText from "../../components/form/InputText";
import InputSelect from "../../components/form/InputSelect";
import { useDeviceDistrict } from "../../components/DeviceDistrictProvider";
import Loader from "../../components/Loader";

const DeviceDistrictForm = ({ control, errors, attrs }) => {
  const { t } = useTranslation();
  const [districtClasses, setDistrictClasses] = useState([]);
  const { getClasses, classes, loading, district } = useDeviceDistrict();

  useEffect(() => {
    getClasses();
  }, [classes]);

  useEffect(() => {
    if (district && district.classes) {
      const districtClasseValuess = district.classes.map((dc) => {
        return { label: dc, value: dc };
      });
      setDistrictClasses(districtClasseValuess);
    }
  }, district);

  if (loading || classes == null) {
    return <Loader />;
  }
  return (
    <Box {...attrs} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <InputText
        name={"name"}
        control={control}
        rules={{ required: true }}
        error={errors.name}
        value={district ? district.name : null}
      />
      <InputSelect
        item={{ attributes: { name: "classes" } }}
        control={control}
        options={classes.map((c) => {
          return { label: c, value: c };
        })}
        isMulti={true}
        value={districtClasses}
        rules={{ required: true }}
      />
      <InputText
        name={"description"}
        control={control}
        multiline={true}
        rows={5}
        maxRows={5}
        error={errors.description}
        value={district ? district.description : null}
      />
    </Box>
  );
};

export default DeviceDistrictForm;
