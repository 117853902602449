import { useEffect, useState } from "react";
import { store } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { nameTransform } from "../../utils/utilsFunctions";
import Typography from "@mui/material/Typography";
const Anomalies = () => {
  const [getAnomalies] = store.useLazyGetAomalyIndexQuery();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const anomalies = useSelector((state) => state?.anomaliesSlice?.anomalies);
  const count = useSelector((state) => state?.anomaliesSlice?.count);
  const { t } = useTranslation();
  useEffect(() => {
    getAnomalies({
      status: "open",
      with_display_name: true,
      page: page,
    });
  }, [page]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
      width: 90,
      sortable: false,
      flex: 1,
    },

    {
      field: "class",
      headerName: t("class"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },
    {
      field: "serial",
      headerName: t("serialNumber"),
      type: "number",
      sortable: false,
      flex: 2,
      headerAlign: "left",
    },

    {
      field: "error",
      headerName: t("error"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },

    {
      field: "date",
      headerName: t("last_detection_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 3,
      headerAlign: "left",
    },
    {
      field: "condominium",
      headerName: t("condominium"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },
    {
      field: "subcondominium",
      headerName: t("subcondominum"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },
    {
      field: "apartment",
      headerName: t("apartament"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <Link to={`/meters/${params.value}`}>
              <VisibilityIcon
                sx={{ float: "right", mt: 1, fill: theme.palette.primary.main }}
              />
            </Link>
          </Box>
        );
      },
    },
  ];

  const rows = anomalies?.map((item) => ({
    id: item?.id,
    class: item?.class ?? "--",
    serial: item?.device_serial_number,
    error: item?.display_name,
    date: t("dt", { val: new Date(item?.anomaly_update_time) }),
    condominium: item?.condominium_name,
    subcondominium: item?.sub_condominium_name,
    apartment: item?.apartment_name,
    actions: item?.device_id,
  }));

  return (
    <Box height={600}>
      <Typography
        variant="h1"
        sx={{ mb: 2, color: theme.palette.primary.main }}
      >
        {t("anomalies")}
      </Typography>
      <StyledDataGrid
        disableColumnMenu
        rows={rows ?? []}
        columns={columns}
        rowCount={count}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        disableSelectionOnClick
        onPageChange={(pageNumber) => {
          setPage(pageNumber + 1);
        }}
        page={page - 1}
        // loading={isLoadingTable}
        paginationMode="server"
        getRowHeight={() => "auto"}
      />
    </Box>
  );
};
export default Anomalies;
