import { useEffect } from "react";
import { store } from "../../redux/store";
import { useParams } from "react-router-dom";
import BlockWidget from "../dashboard/widgets/BlockWidget";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import Grid from "@mui/material/Grid";
import HeatPumpIcon from "@mui/icons-material/HeatPump";
import WaterIcon from "@mui/icons-material/Water";
import { Typography } from "@mui/material";
const BlockStats = () => {
  const { t } = useTranslation();
  const stats = useSelector((state) => state?.condominiumSlice?.stats);
  const hca = stats?.["08"];
  const water = stats?.["07"];
  const heat = stats?.["13"];
  const { id } = useParams();
  const [getStats] = store.useLazyGetBlockStatsQuery();
  useEffect(() => {
    getStats(id);
  }, []);

  useEffect(() => {
    console.log("hca", hca);
  }, [hca]);

  return (
    <>
      {/* <Typography
        sx={{ mt: 1 }}
        color={theme.palette.primary.main}
        variant="h1"
      >
        {`${t("last_update")} : ${t("dt", {
          val: new Date(hca?.date_time ?? water?.date_time ?? heat?.date_timee),
        })}`}
      </Typography> */}

      <Box
        sx={{
          maxHeight: "60vh", // or any suitable height
          overflowY: "auto",
          mt: 2,
          padding: 1,
        }}
      >
        <Grid container spacing={2}>
          {stats?.total_sub_condominiums && (
            <Grid item xs={3}>
              <BlockWidget
                title={t("total_sub_condominiums")}
                value={stats?.total_sub_condominiums}
                icon={<AccountBalanceIcon sx={{ mt: 0.5 }} />}
                tooltip={t("subcondominiums")}
              />
            </Grid>
          )}
          {stats?.total_apartments && (
            <Grid item xs={3}>
              <BlockWidget
                title={t("total_apartments")}
                value={stats?.total_apartments}
                icon={<ApartmentIcon sx={{ mt: 0.5 }} />}
                tooltip={t("apartament")}
              />
            </Grid>
          )}
          {hca?.total_meters && (
            <Grid item xs={3}>
              <BlockWidget
                title={t("total_hca_meters")}
                value={hca?.total_meters}
                icon={
                  <LocalFireDepartmentIcon
                    sx={{ color: theme.palette.warning.main, mt: 0.5 }}
                  />
                }
                tooltip={t("heat_cost")}
              />
            </Grid>
          )}
          {hca?.reading_number && (
            <Grid item xs={3}>
              <BlockWidget
                title={t("reading_hca_number")}
                value={hca?.reading_number}
                icon={
                  <LocalFireDepartmentIcon
                    sx={{ color: theme.palette.warning.main, mt: 0.5 }}
                  />
                }
                tooltip={t("heat_cost")}
              />
            </Grid>
          )}
          {hca?.total_meter_in_errors && (
            <Grid item xs={3}>
              <BlockWidget
                title={t("total_hca_meter_in_errors")}
                value={hca?.total_meter_in_errors}
                icon={
                  <LocalFireDepartmentIcon
                    sx={{ color: theme.palette.warning.main, mt: 0.5 }}
                  />
                }
                tooltip={t("heat_cost")}
              />
            </Grid>
          )}
          {hca?.total_meter_in_not_seen && (
            <Grid item xs={3}>
              <BlockWidget
                title={t("total_hca_meter_in_not_seen")}
                value={hca?.total_meter_in_not_seen}
                icon={
                  <LocalFireDepartmentIcon
                    sx={{ color: theme.palette.warning.main, mt: 0.5 }}
                  />
                }
                tooltip={t("heat_cost")}
              />
            </Grid>
          )}

          {water?.total_meters && (
            <Grid item xs={3}>
              <BlockWidget
                title={t("total_water_meters")}
                value={water?.total_meters}
                icon={<WaterIcon sx={{ mt: 0.5 }} />}
                tooltip={t("water")}
              />
            </Grid>
          )}
          {water?.reading_number && (
            <Grid item xs={3}>
              <BlockWidget
                title={t("reading_water_number")}
                value={water?.reading_number}
                icon={<WaterIcon sx={{ mt: 0.5 }} />}
                tooltip={t("water")}
              />
            </Grid>
          )}
          {water?.total_meter_in_errors && (
            <Grid item xs={3}>
              <BlockWidget
                title={t("total_water_meter_in_errors")}
                value={water?.total_meter_in_errors}
                icon={<WaterIcon sx={{ mt: 0.5 }} />}
                tooltip={t("water")}
              />
            </Grid>
          )}
          {water?.total_meter_in_not_seen && (
            <Grid item xs={3}>
              <BlockWidget
                title={t("total_water_meter_in_not_seen")}
                value={water?.total_meter_in_not_seen}
                icon={<WaterIcon sx={{ mt: 0.5 }} />}
                tooltip={t("water")}
              />
            </Grid>
          )}

          {heat?.total_meters && (
            <Grid item xs={3}>
              <BlockWidget
                title={t("total_heat_meters")}
                value={heat?.total_meters}
                icon={
                  <HeatPumpIcon
                    sx={{ mt: 0.5, color: theme.palette.error.main }}
                  />
                }
                tooltip={t("color_meter")}
              />
            </Grid>
          )}
          {heat?.reading_number && (
            <Grid item xs={3}>
              <BlockWidget
                title={t("reading_heat_number")}
                value={heat?.reading_number}
                icon={
                  <HeatPumpIcon
                    sx={{ color: theme.palette.error.main, mt: 0.5 }}
                  />
                }
                tooltip={t("color_meter")}
              />
            </Grid>
          )}
          {heat?.total_meter_in_errors && (
            <Grid item xs={3}>
              <BlockWidget
                title={t("total_heat_meter_in_errors")}
                value={heat?.total_meter_in_errors}
                icon={
                  <HeatPumpIcon
                    sx={{ color: theme.palette.error.main, mt: 0.5 }}
                  />
                }
                tooltip={t("color_meter")}
              />
            </Grid>
          )}
          {heat?.total_meter_in_not_seen && (
            <Grid item xs={3}>
              <BlockWidget
                title={t("total_heat_meter_in_not_seen")}
                value={heat?.total_meter_in_not_seen}
                icon={
                  <HeatPumpIcon
                    color={theme.palette.error.main}
                    sx={{ mt: 0.5, color: theme.palette.error.main }}
                  />
                }
                tooltip={t("color_meter")}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};
export default BlockStats;
