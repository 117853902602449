import { useEffect, useState, useMemo } from "react";
import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import FormAccordion from "../components/form/formComponents/Accordion";
import { prepareParams } from "../utils";
import { condominiumApi } from "../redux/slices/condominiumApi";
import { useTranslation } from "react-i18next";
import { IwdAuthWrapper } from "../utils/IwdAuthWrapper";
import { useSearchParams } from "react-router-dom";
import { store } from "../redux/store";
import { resetInvites } from "../redux/slices/inviteSlice";
import { resetBlockGroupsData } from "../redux/slices/userGroupSlice";
import { resetUserStatus } from "../redux/slices/condominiumSlice";
import { Box } from "@mui/system";

import {
  setCondomiumShow,
  cleanData,
  setcondominiumTrashId,
} from "../redux/slices/condominiumSlice";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  setTreeExpanded,
  setTreeItem,
  setBtnDetector,
} from "../redux/slices/condominiumSlice";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { setAssociatedusers } from "../redux/slices/condominiumSlice";
import { Link, useNavigate } from "react-router-dom";
import { cleanBalances } from "../redux/slices/balanceSlice";
import { setMessage } from "../redux/slices/snackbarSlice";
import { IconButton, Button, Typography, Grid } from "@mui/material";
import TrashModal from "../components/TrashModal";
import theme from "../theme";
import ModalDialog from "../components/meters/ModalDialog";
import { StyledDataGrid } from "./StyledDataGrid";
import DeleteConfirmModal from "../utils/DeleteConfirmModal";

const PER_PAGE = 10;
const ObjectFieldTemplate = (props) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      rowSpacing={3}
      spacing={2}
    >
      {props.properties.map((element, i) => {
        /* if (i == 5 || i == 4) {
                    return (
                      <Grid key={element.id} alignItems="center" item xs={12}>
                        {element.content}
                      </Grid>
                    );
                  } */
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
/**
 * A custom component for displaying and managing sub-condominium details.
 *
 * @component TextFieldNumber
* @returns {JSX.Element}.
 
 *
 */
const Condominium = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [perPage, setPerPage] = useState(10);
  const [deleteCondominium, { isSuccess: deleteSuccess }] =
    store.useDeleteCondominiumMutation();
  const [formData, setFormData] = useState({});
  const [refreshParams, setRefreshParams] = useState(false);
  const [page, setPage] = useState(1);
  const [deleteModal, setDeletemodal] = useState(false);
  const [deleteOption, setDeleteOption] = useState("");
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [getIndexCondominiums] = store.useLazyCondominiumIndexQuery();
  const [trashId, setTrashId] = useState(null);
  const { t } = useTranslation();
  const condominiums = useSelector(
    (state) => state?.condominiumSlice?.condominiums
  );
  const condominiumTrashId = useSelector(
    (state) => state?.condominiumSlice?.condominiumTrashId
  );
  const metaCount = useSelector((state) => state?.condominiumSlice?.metaCount);

  useEffect(() => {
    getIndexCondominiums({
      page,
      per_page: PER_PAGE,
      class: "Condominium",
      ...paramsList,
    });
  }, [page]);
  useEffect(() => {
    if (refreshParams) {
      getIndexCondominiums({
        page,
        per_page: PER_PAGE,
        class: "Condominium",
        ...paramsList,
      });
      setRefreshParams(false);
    }
  }, [refreshParams]);

  const navigate = useNavigate();

  const deleteOnlyCondominum = () => {
    setDeleteOption("condominum");
    setConfirmDeleteModal(true);
    setDeletemodal(false);
  };
  const deleteCondWithMeters = () => {
    setDeleteOption("all");
    setConfirmDeleteModal(true);
    setDeletemodal(false);
  };
  const onDeleteClick = (id) => {
    setTrashId(id);
    setDeletemodal(true);
  };
  useEffect(() => {
    if (condominiumTrashId && deleteOption === "condominum") {
      deleteCondominium({
        id: condominiumTrashId,
        delete_meters: false,
      });

      dispatch(setcondominiumTrashId(null));
    }
  }, [condominiumTrashId, deleteOption]);
  useEffect(() => {
    dispatch(cleanBalances());
  }, []);
  useEffect(() => {
    if (condominiumTrashId && deleteOption === "all") {
      deleteCondominium({
        id: condominiumTrashId,
        delete_meters: true,
      });

      dispatch(setcondominiumTrashId(null));
    }
  }, [condominiumTrashId, deleteOption]);
  useEffect(() => {
    dispatch(setAssociatedusers(null));
    dispatch(resetInvites());
    dispatch(resetUserStatus());
    dispatch(resetBlockGroupsData());
  }, []);
  /* useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("successfully_deleted")));
      getIndexCondominiums({
        page,
        per_page: PER_PAGE,
        class: "Condominium",
        ...paramsList,
      });
    }
  }, [deleteSuccess]); */
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "name",
      headerName: t("name"),
      type: "string",
      editable: false,
      flex: 1,

      sortComprator: () => {
        return console.log("dadadadada");
      },
    },
    {
      field: "address",
      headerName: t("street_address"),
      type: "string",

      editable: false,
      flex: 1,
      editable: false,
    },
    /*    {
      field: "city",
      headerName: t("city"),
      type: "string",

      editable: false,
      flex: 1,
      editable: false,
    }, */
    {
      field: "province",
      headerName: t("province"),
      type: "string",

      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "zip_code",
      headerName: t("zip_code"),
      type: "string",

      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",

      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            {/* <IconButton
              sx={{ float: "right" }}
              onClick={() => onDeleteClick(params.value)}
            >
              <DeleteIcon sx={{ fill: theme.palette.error.main }} />
            </IconButton> */}
            <Link to={`/condominiums/${params.value}`}>
              <VisibilityIcon
                onClick={() => {
                  console.log(params.value);
                }}
                sx={{
                  float: "right",
                  mt: 1,
                  mr: 2,
                  fill: theme.palette.primary.main,
                }}
              />
            </Link>
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    dispatch(setCondomiumShow(null));
    dispatch(cleanData());
    dispatch(setTreeExpanded(["condominum"]));
    dispatch(setTreeItem("condominum"));
    dispatch(setBtnDetector(1));
  }, []);
  const tableRows = useMemo(
    () =>
      (condominiums ?? []).map((item) => ({
        ...item?.attributes?.fields,
        id: Number(item.id),
        actions: Number(item.id),
      })),
    [condominiums]
  );
  /**
   * This object, paramsList, extracts and stores values from the search parameters in the URL query string.
   * The values are accessed using the 'searchParams' object's 'get' method, providing the parameter name as an argument.
   * @param {string} name parameter extracted from the URL query string
   * @param {string} address parameter extracted from the URL query string
   * @param {string} province parameter extracted from the URL query string
   * @param {string} zip_code parameter extracted from the URL query string
 
   */
  const paramsList = {
    name: searchParams.get("name") ?? "",
    address: searchParams.get("address") ?? "",
    province: searchParams.get("province") ?? "",
    zip_code: searchParams.get("zip_code") ?? "",
  };
  const schema = {
    title: "New user",
    type: "object",
    properties: {
      /*  avatar_url: {
              $id: 1,
              title: t("avatar_url"),
              type: "string",
            }, */
      name: {
        $id: "12",
        type: "string",
        title: t("name"),
        default: paramsList.name,
      },
      address: {
        $id: "3",
        type: "string",
        title: t("street_address"),
        default: paramsList.address,
      },
      province: {
        $id: "4",
        type: "string",
        title: t("province"),
        default: paramsList.province,
      },
      zip_code: {
        $id: "2",
        type: "string",
        title: t("zip_code"),
        default: paramsList.zip_code,
      },
    },
  };
  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: t("search"),
      classNames: "submit-button",
    },
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
  };
  /**
   * Function that handles the submission of a form or user input. It prepares the parameters,
   * updates the URL with the new parameters, and triggers necessary state updates.
   * @param {Object} obj - The object containing the form data or user input.
   */
  const onSubmitClick = (obj) => {
    // Add pagination parameters to the object
    obj["page"] = page;
    obj["per_page"] = perPage;
    // Prepare the parameters for the URL
    const params = prepareParams(obj);
    // Update the URL with the new parameters
    navigate({
      pathname: "/condominiums",
      search: params.toString(),
    });
    // Reset the page to 1 and trigger a refresh of parameters
    setPage(1);
    setRefreshParams(true);
  };

  return (
    <Box>
      <Typography
        sx={{ mb: 4, color: theme.palette.primary.main }}
        variant="h1"
      >
        {t("buildings")}
      </Typography>
      <FormAccordion width={"100%"}>
        <Box sx={{ mb: 2 }}>
          <Form
            schema={schema}
            uiSchema={uiSchema}
            //fields={fields}
            formData={formData}
            onSubmit={(values) => onSubmitClick(values.formData)}
            onChange={(changeEvent) => setFormData(changeEvent.formData)}
            validator={validator}
            showErrorList={false}
            noHtml5Validate
          />
        </Box>
      </FormAccordion>

      <TrashModal title={t("delete_user_title")} text={t("delete_user_text")} />
      {tableRows && (
        <Box sx={{ height: "60vh", /* width: "96.3%", */ mt: 2 }}>
          <StyledDataGrid
            disableColumnMenu
            rows={tableRows}
            columns={columns}
            /*       onRowClick={({ id, row: { name } }) => {
              window.sessionStorage.setItem("bcName", name);
              navigate(`/condominiums/${id}`);
            }} */
            rowCount={metaCount}
            pageSize={perPage}
            rowsPerPageOptions={[perPage]}
            disableSelectionOnClick
            onSortModelChange={(model) => console.log("test")}
            onPageChange={(pageNumber) => {
              setPage(pageNumber + 1);
            }}
            onSelectionModelChange={(model) => console.log(model)}
            page={page - 1}
            sortingMode="server"
            // loading={isLoadingTable}
            paginationMode="server"
            getRowHeight={() => "auto"}
          />
        </Box>
      )}
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <IwdAuthWrapper
          children={
            <Button
              variant="outlined"
              sx={{
                mt: 2,
                mr: 2,
                background: theme.palette.primary.main,
                color: "white",
                "&:hover": { backgroundColor: theme.palette.primary.main },
              }}
              onClick={() => {
                navigate("/condominium/import");
              }}
            >
              {t("import_condominium")}
            </Button>
          }
          section={"import_block"}
        />
        <IwdAuthWrapper
          children={
            <Button
              variant="outlined"
              sx={{
                mt: 2,

                background: theme.palette.primary.main,
                color: "white",
                "&:hover": { backgroundColor: theme.palette.primary.main },
              }}
              onClick={() => {
                window.sessionStorage.setItem("bcName", "Nuovo condominio");
                return navigate("/condominiums/new");
              }}
            >
              {t("new_condominium")}
            </Button>
          }
          section={"create_block"}
        />
      </Box>
    </Box>
  );
};

export default Condominium;
