import { IconButton, Button, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useTransition } from "react";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import { StyledDataGrid } from "../StyledDataGrid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CalculateIcon from "@mui/icons-material/Calculate";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import Tooltip from "@mui/material/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import { store } from "../../redux/store";
import { useTranslation } from "react-i18next";
import { setBalancType } from "../../redux/slices/balanceSlice";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { useEffect } from "react";
import { setCondomiumShow } from "../../redux/slices/condominiumSlice";
import { setBalanceTrashId } from "../../redux/slices/balanceSlice";
import InfiniteTable from "../InfiniteTable";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { setRecords } from "../../redux/slices/balanceSlice";
import theme from "../../theme";
const Balances = () => {
  const dispatch = useDispatch();

  const [deleteBalance, { isSuccess: deleteSuccess }] =
    store.useDeleteBalanceMutation();
  const [trashId, setTrashId] = useState(null);
  const [trashModal, setTrashModal] = useState(false);
  const { id } = useParams();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const balances = useSelector((state) => state?.balanceSlice?.balances);
  const recorsLength = useSelector(
    (state) => state?.balanceSlice?.recorsLength
  );
  const haveRecords = useSelector((state) => state?.balanceSlice?.haveRecords);
  const count = useSelector((state) => state?.balanceSlice?.balancesCount);
  const balanceTrashId = useSelector((state) => state?.balanceSlice?.trashId);
  const [getBalances, { isFetching }] = store.useLazyGetBalanceIndexQuery();
  const navigate = useNavigate();
  const currentPath = window.location.pathname;

  useEffect(() => {
    getBalances({
      condominium_id: id,
      page: page,
      per_page: perPage,
    });
  }, [page, deleteSuccess]);
  const onDeleteClick = (id) => {
    debugger;
    setTrashId(id);
    setTrashModal(true);
  };

  useEffect(() => {
    dispatch(setBalancType(null));
    dispatch(setCondomiumShow(null));
  }, []);
  const columns = [
    {
      field: "name",
      headerName: t("name"),

      sortable: false,
      editable: false,
      flex: 2,
    },

    {
      field: "balance_type",
      headerName: t("balance_type"),

      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "condominium",
      headerName: t("condominium"),

      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "address",
      headerName: t("address"),

      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "region",
      headerName: t("region"),

      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "limit",
      headerName: t("limit"),

      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "month_balance",
      headerName: t("month_balance"),

      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "week_balance",
      headerName: t("week_balance"),

      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "day_balance",
      headerName: t("day_balance"),

      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "date",
      headerName: t("last_balance_date"),

      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "type",
      headerName: t("type"),

      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        if (params.value === true) {
          return (
            <Tooltip title={t("simulated")}>
              <OnlinePredictionIcon sx={{ fill: "#8884d8" }} />
            </Tooltip>
          );
        } else if (params.value === false) {
          return (
            <Tooltip title={t("calculated")}>
              <CalculateIcon sx={{ fill: "green" }} />
            </Tooltip>
          );
        } else {
          return "--";
        }
      },
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,

      headerAlign: "right",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
            <IconButton
              onClick={() =>
                navigate(
                  `/condominiums/${params?.value?.block_id}/balance/${params?.value.id}`
                )
              }
            >
              <VisibilityIcon sx={{ color: theme.palette.primary.main }} />
            </IconButton>
            <IconButton onClick={() => onDeleteClick(params?.value.id)}>
              <DeleteIcon sx={{ color: theme.palette.error.main }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const styleSelector = (params) => {
    console.log("parix", params);
    const datastr = params.row.readTime;
    const date = new Date();
    console.log(date);

    if (
      +params?.row?.limit &&
      +params?.row?.day_balance &&
      +params?.row?.day_balance > +params?.row?.limit
    ) {
      return `super-app-theme--Rejected`;
    }
  };
  const rows = balances?.map((item) => ({
    id: item?.id,
    name: `${item?.code} - ${item?.name ?? " "}`,

    balance_type: t(item?.balance_type),
    limit: item?.limit ?? "--",
    condominium: item?.meta?.block_name,
    address: item?.meta?.address,
    region: item?.meta?.region,
    month_balance: t("number", {
      val: item?.meta?.month?.value,
      maximumFractionDigits: 3,
    }),
    week_balance: t("number", {
      val: item?.meta?.week?.value,
      maximumFractionDigits: 3,
    }),
    day_balance: t("number", {
      val: item?.meta?.day?.value,
      maximumFractionDigits: 3,
    }),

    date: t("dt", { val: new Date(item?.meta?.day?.date) }),
    type: item?.meta?.day?.computed,
    actions: {
      id: item?.id,
      block_id: item?.condominium_id,
    },
  }));
  useEffect(() => {
    if (balanceTrashId) {
      deleteBalance(balanceTrashId);
    }
  }, [balanceTrashId]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("successfully_deleted")));
      setTrashModal(false);
      dispatch(setBalanceTrashId(null));
    }
  }, [deleteSuccess]);

  return (
    <>
      <DeleteConfirmModal
        trashModal={trashModal}
        setTrashModal={setTrashModal}
        text={t("balance_delete_text")}
        title={t("balance_delete_title")}
        deleteFunc={() => setBalanceTrashId(trashId)}
      />
      <Typography variant="h3" color={theme.palette.primary.main}>
        {t("balances")}
      </Typography>
      <Box sx={{ height: 500, mt: 2 }}>
        <StyledDataGrid
          getRowClassName={(params) => styleSelector(params)}
          disableColumnMenu
          rows={rows ?? []}
          columns={columns}
          rowCount={count}
          pageSize={perPage}
          rowsPerPageOptions={[perPage]}
          disableSelectionOnClick
          onPageChange={(pageNumber) => {
            setPage(pageNumber + 1);
          }}
          page={page - 1}
          // loading={isLoadingTable}
          paginationMode="server"
          getRowHeight={() => "auto"}
        />
      </Box>
      {/*  <InfiniteTable
        styles={{ height: 500, mt: 2 }}
        rows={rows ?? []}
        columns={columns}
        page={page}
        perPage={perPage}
        setPage={setPage}
        isSuccess={isFetching}
        paginationMode={"server"}
        dataLength={balances?.length}
        records={haveRecords}
        recordsFn={setRecords}
        recorsLength={recorsLength}
      /> */}
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent={"flex-end"}
        sx={{ mt: 2 }}
      >
        {/*   <Button
          variant="contained"
          onClick={() => navigate(`/condominiums/${id}/balance/create`)}
        >
          {t("create_balance")}
        </Button> */}
      </Box>
    </>
  );
};

export default Balances;
