import { StyledDataGrid } from "../../pages/StyledDataGrid";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { nameTransform } from "../../utils/utilsFunctions";
import { useNavigate } from "react-router-dom";
import ModalDialog from "../meters/ModalDialog";
import { Form } from "@rjsf/mui";
import {
  Button,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setNewDeviceId } from "../../redux/slices/condominiumSlice";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import MeterSubCreate from "../form/MeterSubCreate";
import MeterCreate from "../form/MeterCreate";
import TagsCustomWidget from "../form/formComponents/customWidgets/TagsCustomwidget";
import validator from "@rjsf/validator-ajv6";
import { IwdAuthWrapper } from "../../utils/IwdAuthWrapper";
import { store } from "../../redux/store";
import theme from "../../theme";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import IconButton from "@mui/material/IconButton";
import SingleDatePicker from "../form/formComponents/SingleDatePicker";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties.map((element, i) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const SubstitutionModal = ({ close, open }) => {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [perPage, setPerPage] = useState(10);
  const [getCondominiumShow, { isFetching }] =
    store.useLazyCondominiumShowQuery();
  const [defValue, setDefValue] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [identifier, setIdentifier] = useState(null);
  const [formData, setFormData] = useState({});
  const [newSerial, setNewSerial] = useState(null);
  const [refreshForm, setRefreshform] = useState(false);
  const [substitution, { isSuccess, isError }] =
    store.useSubstitutionMeterMutation();
  const { t } = useTranslation();
  const [serial, setSerial] = useState(null);
  const [getFreeMeters, { isPending }] = store.useLazyGetFreeMetersQuery();
  const selectedMeterType = useSelector(
    (state) => state.meterSlice?.meterShow?.attributes?.fields?.type
  );
  const meters = useSelector((state) => state?.condominiumSlice?.freeMeters);
  const count = useSelector((state) => state?.condominiumSlice?.freeMeterCount);
  const meterShow = useSelector((state) => state?.meterSlice?.meterShow);
  const newDeviceId = useSelector(
    (state) => state?.condominiumSlice?.newDeviceId
  );
  const newDeviceSerial = useSelector(
    (state) => state?.condominiumSlice?.newDeviceSerial
  );
  const subCondominiumName = useSelector(
    (state) => state?.condominiumSlice?.subCondominiumName
  );
  const apartamentName = useSelector(
    (state) => state?.condominiumSlice?.apartamentName
  );
  const meterFields = meterShow?.attributes?.fields;
  const fields = {
    date: SingleDatePicker,
  };
  const handleSerial = (e) => {
    setSerial(e.target.value);
  };

  const openConfirmModal = () => {
    setConfirmModal(true);
  };
  const closeConfirmModal = () => {
    setConfirmModal(false);
  };

  const lastValueSelector = () => {
    if (meterFields?.meter_type == "Calorie") {
      return meterFields?.heat_last_reading?.value
        ? meterFields?.heat_last_reading?.value
        : 0;
    } else if (meterFields?.meter_type == "Frigorie") {
      return meterFields?.cold_last_reading?.value
        ? meterFields?.cold_last_reading?.value
        : 0;
    } else {
      return meterFields?.last_reading?.value
        ? meterFields?.last_reading?.value
        : 0;
    }
  };
  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      prev_value: lastValueSelector(),
    }));
  }, [meterShow]);
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,

    replace_date: {
      "ui:field": "date",
    },
  };
  useEffect(() => {
    console.log("defik", defValue);
  }, [defValue]);
  const currentDate = new Date();
  const isoDate = currentDate.toISOString();
  const schema = {
    title: "New Device",
    type: "object",
    properties: {
      replace_date: {
        $id: "1",
        type: "string",
        title: t("replace_date"),
        label: "replace_date",
        default: isoDate,
      },
      prev_value: {
        $id: "1",
        type: "string",
        title: t("prev_value"),
      },

      required: [],
    },
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
    },

    {
      field: "class",
      headerName: t("class"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },
    {
      field: "serial",
      headerName: t("serialNumber"),
      type: "number",
      sortable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "metrological_serial",
      headerName: t("metrological_serial"),
      type: "number",
      sortable: false,

      flex: 2,
      headerAlign: "left",
    },
    {
      field: "address",
      headerName: t("street_address"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },

    /*    {
      field: "diameter",
      headerName: t("diameter"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      headerAlign: "left",
    },
 */
    {
      field: "configdate",
      headerName: t("configuration_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <IconButton
              onClick={() =>
                onSubstitutionClick(params?.value.id, params?.value?.serial)
              }
              disabled={!formData.replace_date}
            >
              <PublishedWithChangesIcon
                sx={{
                  float: "right",
                  mt: 1,
                  fill: formData.replace_date && theme.palette.primary.main,
                }}
              />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    console.log("meters", meters);
  }, [meters]);

  const tableRows = meters?.map((item) => ({
    id: item.id ? item.id : "--",
    /*    class: item?.attributes?.class ? item?.attributes?.class : "--", */
    /*    tag: item?.attributes?.fields?.tags
      ? item?.attributes?.fields?.tags.map((item) => item)
      : "--", */
    class: item?.attributes?.class
      ? nameTransform(
          item?.attributes?.class === "lorawan_device"
            ? "LoRaWAN"
            : item?.attributes?.class
        )
      : "--",
    serial: item?.attributes?.serial_number
      ? item?.attributes?.serial_number
      : "--",
    metrological_serial: item?.attributes?.fields?.serial ?? "--",
    address: item?.attributes?.fields?.address
      ? item?.attributes?.fields?.address
      : "--",
    /*  pod: item?.attributes?.fields?.pod ? item?.attributes?.fields?.pod : "--", */
    /*     diameter: item?.attributes?.fields?.diameter
      ? item?.attributes?.fields?.diameter
      : "--", */
    configdate: item?.attributes?.inserted_at
      ? t("dt", { val: new Date(item?.attributes?.inserted_at) })
      : "--",
    actions: {
      id: item.attributes?.identifier,
      serial: item?.attributes?.fields?.serial,
    },
  }));

  const onSubstitutionClick = (identifier, serial) => {
    setNewSerial(serial);
    setIdentifier(identifier);
    setConfirmModal(true);
  };
  const onConfirmClick = () => {
    const attributes = {
      old_device_id: meterFields?.identifier,
      new_device_id: identifier,
      replace_date: formData?.replace_date,
      last_previous_reading_value: formData?.prev_value.toString(),
    };
    substitution({
      id: id,
      attributes: attributes,
    });
    setConfirmModal(false);
  };

  useEffect(() => {
    if (newDeviceId) {
      setNewSerial(newDeviceSerial);
      const attributes = {
        old_device_id: meterFields?.identifier,
        new_device_id: newDeviceId,
        replace_date: formData?.replace_date,
        last_previous_reading_value: formData?.prev_value,
      };
      substitution({
        id: id,
        attributes: attributes,
      });
    }
  }, [newDeviceId]);
  const currentUrl = window.location.href;
  useEffect(() => {
    const currentUrl = window.location.href;

    const match = currentUrl.match(/condominiums\/.*?apartamentMeter/);
    console.log("trima", match?.[0]);
  }, [currentUrl]);
  useEffect(() => {
    if (isSuccess) {
      debugger;
      dispatch(setMessage(t("meter_sub_succ")));
      dispatch(
        setNewDeviceId({
          id: null,
          serial: null,
        })
      );

      setRefreshform(true);
      close();
      getCondominiumShow(id);
      const currentUrl = window.location.href;

      const match = currentUrl.match(/condominiums\/.*?apartamentMeter/);
      const resUrl = match?.[0];
      navigate(`/${resUrl}/${newSerial}`);
    }
  }, [isSuccess]);

  const onSearchClick = () => {
    const attributes = {
      parent_is_nil: true,
      decommissioned: false,
      type: JSON.stringify([`${selectedMeterType}`]),
      serial_number: serial,
    };
    getFreeMeters(attributes);
  };
  useEffect(() => {
    if (open) {
      const attributes = {
        parent_is_nil: true,
        decommissioned: false,
        type: JSON.stringify([`${selectedMeterType}`]),
        page: page,
        per_page: perPage,
      };
      getFreeMeters(attributes);
    }
  }, [open, page]);

  useEffect(() => {
    console.log("subfrmdt", formData);
  }, [formData]);

  return (
    <Box sx={{ height: "70vh", width: "70rem" }}>
      <ModalDialog
        open={confirmModal}
        close={closeConfirmModal}
        title={" "}
        cancell={false}
      >
        <Box>
          <Typography variant="h5">{t("sub_confirm_text")}</Typography>
          <Box display={"flex"} justifyContent={"space-between"} sx={{ mt: 4 }}>
            <Button onClick={closeConfirmModal} variant="contained">
              {t("no")}
            </Button>
            <Button onClick={onConfirmClick} variant="contained">
              {t("yes")}
            </Button>
          </Box>
        </Box>
      </ModalDialog>
      <Box sx={{ mt: 2 }}>
        <Form
          schema={schema}
          fields={fields}
          formData={formData}
          onChange={(changeEvent) => setFormData(changeEvent.formData)}
          validator={validator}
          uiSchema={uiSchema}
          children={true}
          showErrorList={false}
          noHtml5Validate
        />
      </Box>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {t("substitutution_from_exists")}
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ mt: 2, mb: 2 }} display={"flex"}>
            <TextField
              fullWidth
              label={t("serial_number")}
              onChange={handleSerial}
            />
            <IconButton onClick={onSearchClick}>
              <SearchIcon />
            </IconButton>
          </Box>
          {count ? (
            <>
              <Box height={450}>
                <StyledDataGrid
                  disableColumnMenu
                  rows={tableRows ?? []}
                  columns={columns}
                  rowCount={count}
                  pageSize={perPage}
                  rowsPerPageOptions={[perPage]}
                  disableSelectionOnClick
                  onPageChange={(pageNumber) => {
                    setPage(pageNumber + 1);
                  }}
                  page={page - 1}
                  // loading={isLoadingTable}
                  paginationMode="server"
                  getRowHeight={() => "auto"}
                />
              </Box>
            </>
          ) : (
            <Typography
              textAlign={"center"}
              variant="h3"
              color={theme.palette.primary.main}
            >
              {t("no_available_data")}
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mt: 2 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {t("create_meter")}
        </AccordionSummary>
        <AccordionDetails>
          <MeterSubCreate
            refreshForm={refreshForm}
            setRefreshform={setRefreshform}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
export default SubstitutionModal;
