import * as React from "react";
import { useState, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en";

import "dayjs/locale/it";

import TextField from "@mui/material/TextField";
import Box from "@material-ui/core";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const SingleDatePicker = (props) => {
  const defaultEndtDate = props?.schema?.properties?.to_date?.default;
  const label = props?.schema?.label;

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [firstRender, setFirstRender] = useState(true);
  const [endDate, setEndDate] = useState(
    defaultEndtDate ? defaultEndtDate : null
  );
  let EndDateerrorMessage = props?.errorSchema?.to?.__errors[0];

  const currentDate = new Date();

  const nextWeekDate = new Date(currentDate);
  nextWeekDate.setDate(nextWeekDate.getDate() + 7);

  const isoFormatDate = nextWeekDate.toISOString();

  const newTo = new Date(endDate);
  useEffect(() => {
    console.log("currentLanguage", currentLanguage);
  }, [currentLanguage]);
  const invalidEndDate = newTo == "Invalid Date" ? "Invalid Date" : null;
  /*   useEffect(() => {
    console.log("fromdtwid", props);
    if (props?.formData?.to_date == null) {
    

      setEndDate(null);
    }
  }, [props]);
 */
  useEffect(() => {
    if (props?.schema?.label == "replace_date" && firstRender) {
      setEndDate(props?.schema?.default);
      setFirstRender(false);
    }
  }, [props]);
  useEffect(() => {
    if (newTo == "Invalid Date") {
      props.onChange({
        to: null,
      });
    }
  }, [newTo]);
  useEffect(() => {
    if (newTo != "Invalid Date" && endDate !== null) {
      const end = new Date(endDate);
      const to = new Date(
        Date.UTC(end.getFullYear(), end.getMonth(), end.getDate())
      );
      const toIso = to.toISOString();
      if (label === "read_time") {
        props.onChange({
          read_time: to ? to.toISOString() : null,
        });
      }
      if (label === "configuration_date") {
        props.onChange({
          inserted_at: to ? to.toISOString() : null,
        });
      }
      if (!label || label === t("error_date")) {
        props.onChange({
          to_date: to ? to.toISOString() : null,
        });
      }
      if (props.name == "to_date") {
        debugger;
        props.onChange(toIso);
      }
      if (props?.schema?.label == "replace_date") {
        debugger;
        props.onChange(toIso);
      }
    }
  }, [endDate]);

  useEffect(() => {
    if (props.defaultDate) {
      setEndDate(isoFormatDate);
    }
  }, []);
  useEffect(() => {
    if (props?.schema?.refreshData) {
      setEndDate(null);

      props?.schema?.setRefreshData(false);
    }
  }, [props?.schema?.refreshData]);
  useEffect(() => {
    console.log("datePROPS", props);
  }, [props]);

  return (
    <Grid container>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={currentLanguage.split("-")[0]}
      >
        <Grid item xs={12}>
          <DatePicker
            renderInput={(props) => (
              <TextField
                sx={{ width: "100%" }}
                {...props}
                error={
                  EndDateerrorMessage ? EndDateerrorMessage : invalidEndDate
                }
                helperText={
                  EndDateerrorMessage ? EndDateerrorMessage : invalidEndDate
                }
              />
            )}
            label={label ? t(label) : t("end_date")}
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
          />
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
};

export default SingleDatePicker;
