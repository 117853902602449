import { store } from "../../redux/store";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { useSelector, useDispatch } from "react-redux";
import { prepareBodyWithoutId } from "../../utils/api_params";
import DoneIcon from "@mui/icons-material/Done";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { IwdAuthWrapper } from "../../utils/IwdAuthWrapper";
import Card from "@mui/material/Card";
import { Box, Button, Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import SingleDatePicker from "../form/formComponents/SingleDatePicker";
import ModalDialog from "../meters/ModalDialog";
import Tooltip from "@mui/material/Tooltip";
import { useParams } from "react-router-dom";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import theme from "../../theme";
import { loadingOff, loadingOn } from "../../redux/slices/loadingSlice";
import {
  setLockSuccess,
  setUnlockSuccess,
} from "../../redux/slices/condominiumSlice";
import { setMessage, setSeverity } from "../../redux/slices/snackbarSlice";

const CondominiumInviteTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [disasochApp, { isSuccess: disasocSuccess }] =
    store.useDisassociateApartmentMutation();
  const id = useSelector(
    (state) => state?.condominiumSlice?.condominiumIdentifier
  );
  const condId = useSelector((state) => state?.condominiumSlice?.condominiumId);
  const lockSuccess = useSelector(
    (state) => state?.condominiumSlice?.lockSuccess
  );
  const unlockSuccess = useSelector(
    (state) => state?.condominiumSlice?.unlockSuccess
  );
  const [lockUsers] = store.useLazyLockUsersQuery();
  const [getUserStatus, { isSuccess }] = store.useLazyGetUsersStatusQuery();
  const [firstRender, setFirstRender] = useState(true);
  const [unlockUsers] = store.useLazyUnlockUsersQuery();
  const [modal, setModal] = useState(false);
  const [disasochModal, setDisasochModal] = useState(false);
  const [disasochId, setDisasochId] = useState(null);
  const invites = useSelector((state) => state?.inviteSlice?.invites);
  const usersStatus = useSelector(
    (state) => state?.condominiumSlice?.usersStatus
  );
  const [expireDate, setExpireDate] = useState();
  const count = useSelector((state) => state?.inviteSlice?.count);
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  let fileName = "";
  const [getInviteList] = store.useLazyGetInviteListQuery();

  const onDisasochClick = (id) => {
    setDisasochId(id);
    setDisasochModal(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "apartament",
      headerName: t("apartament"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "used",
      headerName: t("used"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
      renderCell: (params) => {
        if (params.value == true) {
          return <DoneIcon sx={{ color: theme.palette.success.main }} />;
        } else {
          return <CloseIcon sx={{ color: theme.palette.error.main }} />;
        }
      },
    },
    {
      field: "date",
      headerName: t("expire_date"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: t("actions"),
      headerAlign: "right",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <IwdAuthWrapper
            children={
              <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
                <Tooltip title={t("disasoch_app")}>
                  <IconButton
                    disabled={!params?.value?.used}
                    onClick={() => onDisasochClick(params?.value?.id)}
                  >
                    <PersonRemoveIcon
                      sx={{
                        fill: params?.value?.used && theme.palette.error.main,
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            }
            section={"remove_invite_btn"}
          />
        );
      },
    },
  ];

  const rows = invites?.map((item, i) => ({
    id: i,
    apartament: item?.device?.name,
    used: item?.used,
    date: t("dt", { val: new Date(item?.expiresAt) }),
    actions: {
      id: item?.id,
      used: item?.used,
    },
  }));
  useEffect(() => {
    console.log("invix", invites);
  }, [invites]);
  useEffect(() => {});
  useEffect(() => {
    if (!firstRender) {
      getUserStatus(id);
    }
  }, [lockSuccess, unlockSuccess]);
  useEffect(() => {
    if (!firstRender) {
      getInviteList({
        root_device_id: id,
        with_device: true,
        per_page: perPage,
        page: page,
      });
    }
  }, [page]);
  useEffect(() => {
    if (usersStatus == null) {
      getUserStatus(id);
    }
    if (!invites) {
      getInviteList({
        root_device_id: id,
        with_device: true,
        per_page: perPage,
        page: page,
      });
    }
    setFirstRender(false);
  }, []);

  useEffect(() => {
    if (lockSuccess) {
      dispatch(setMessage(t("lock_success")));
      dispatch(setLockSuccess(false));
    }
  }, [lockSuccess]);
  useEffect(() => {
    if (unlockSuccess) {
      dispatch(setMessage(t("unlock_success")));
      dispatch(setUnlockSuccess(false));
    }
  }, [unlockSuccess]);
  const bearer = localStorage.getItem("user_token");
  const onCreateInviteClick = () => {
    dispatch(loadingOn());
    const uuid = uuidv4();
    const attributes = {
      root_device_id: id.toString(),
      expires_at: expireDate.to_date,
      uuid: uuid,
    };

    fetch(`${window.BASE_URL}/api/v1/association_code`, {
      method: "POST",
      headers: {
        "Content-Type": "application/vnd.api+json",
        Authorization: `Bearer ${bearer}`,
      },
      body: JSON.stringify(
        prepareBodyWithoutId(attributes, "association_code")
      ),
    })
      .then((response) => {
        const contentDisposition = response.headers.get("content-disposition");
        fileName = contentDisposition
          .split(";")
          .find((part) => part.trim().startsWith("filename="))
          .split("=")[1]
          .trim();
        console.log("respya", fileName);
        if (!response.ok) {
          throw new Error(
            `Network response was not ok, status: ${response.status}`
          );
        }

        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        console.log("юра", url);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
      })
      .then(() => {
        dispatch(loadingOff());
      })
      .catch((error) => {
        console.error("Error during fetch:", error);

        dispatch(setSeverity("error"));
        dispatch(loadingOff());
        dispatch(
          setMessage(
            `L'esportazione non è riuscita. Contatta l'amministratore di sistema per ulteriore assistenza.`
          )
        );
      });
  };
  const onLockClick = () => {
    setModal(true);
  };

  useEffect(() => {
    if (disasocSuccess) {
      dispatch(setMessage(t("disasoc_app_success")));
      setDisasochId(null);
      setDisasochModal(false);
      getInviteList({
        root_device_id: id,
        with_device: true,
        per_page: perPage,
        page: page,
      });
    }
  }, [disasocSuccess]);
  return (
    <>
      <>
        <ModalDialog
          open={disasochModal}
          title={t("disasoch_app")}
          cancell={false}
        >
          <>
            <Typography>{t("disasoc_app_text")}</Typography>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              sx={{ mt: 3 }}
            >
              <Button
                onClick={() => setDisasochModal(false)}
                variant="contained"
              >
                {t("no")}
              </Button>
              <Button
                onClick={() => {
                  disasochApp(disasochId);
                }}
                variant="contained"
              >
                {t("yes")}
              </Button>
            </Box>
          </>
        </ModalDialog>
        <ModalDialog
          open={modal}
          title={!usersStatus ? t("lock_users_title") : t("unlock_users_title")}
          cancell={false}
        >
          <>
            <Typography>
              {!usersStatus ? t("lock_users_text") : t("unlock_users_text")}
            </Typography>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              sx={{ mt: 2 }}
            >
              <Button onClick={() => setModal(false)} variant="contained">
                {t("no")}
              </Button>
              <Button
                onClick={() => {
                  !usersStatus ? lockUsers(id) : unlockUsers(id);
                  setModal(false);
                }}
                variant="contained"
              >
                {t("yes")}
              </Button>
            </Box>
          </>
        </ModalDialog>
        <Card sx={{ height: 130 }}>
          <CardContent>
            <Box>
              <SingleDatePicker defaultDate={true} onChange={setExpireDate} />
              <Button
                onClick={onCreateInviteClick}
                disabled={!expireDate}
                variant="contained"
                sx={{ float: "right", mt: 2 }}
              >
                {t("create")}
              </Button>

              {(usersStatus == true || usersStatus == false) && (
                <>
                  {usersStatus == false && (
                    <IwdAuthWrapper
                      children={
                        <Button
                          onClick={() => onLockClick()}
                          variant="contained"
                          sx={{
                            float: "right",
                            mt: 2,
                            mr: 2,
                            background: theme.palette.error.main,
                            "&:hover": {
                              backgroundColor: theme.palette.error.main,
                            },
                          }}
                        >
                          {t("lock_all_users")}
                        </Button>
                      }
                      section={"lock_user_btn"}
                    />
                  )}
                  {usersStatus == true && (
                    <IwdAuthWrapper
                      children={
                        <Button
                          onClick={() => onLockClick()}
                          variant="contained"
                          sx={{
                            float: "right",
                            mt: 2,
                            mr: 2,
                            background: theme.palette.success.main,
                            "&:hover": {
                              backgroundColor: theme.palette.success.main,
                            },
                          }}
                        >
                          {t("unlock_all_users")}
                        </Button>
                      }
                      section={"lock_user_btn"}
                    />
                  )}
                </>
              )}
            </Box>
          </CardContent>
        </Card>
        <Box sx={{ height: 521, mt: 4 }}>
          <StyledDataGrid
            disableColumnMenu
            rows={rows ?? []}
            columns={columns}
            rowCount={count}
            pageSize={perPage}
            rowsPerPageOptions={[perPage]}
            disableSelectionOnClick
            onPageChange={(pageNumber) => {
              setPage(pageNumber + 1);
            }}
            page={page - 1}
            // loading={isLoadingTable}
            paginationMode="server"
            getRowHeight={() => "auto"}
          />
        </Box>
      </>
    </>
  );
};
export default CondominiumInviteTab;
