import { Routes, Route } from "react-router-dom";
import RequireAuth from "../components/RequireAuth";
import NotFoundPage from "../utils/NotfoundPage";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { hasCommonValue } from "./api_params";

export const IwdRoute = ({ component, section, path, auth }) => {
  const permessions = window.MENU[section];
  const userInfo = useSelector((state) => state?.userPreferenceSlice.userInfo);
  useEffect(() => {
    console.log("compix", hasCommonValue(roles, permessions?.roles));
  }, [component]);

  const roles = useSelector((state) => state?.userPreferenceSlice.roles);
  const groups = useSelector((state) => state?.userPreferenceSlice.groups);

  const render = () => {
    if (permessions?.groups?.length > 0) {
      if (hasCommonValue(groups, permessions?.groups)) {
        return true;
      } else {
        return false;
      }
    }
    if (permessions?.groups?.length == 0 || !permessions?.groups) {
      if (hasCommonValue(roles, permessions?.roles)) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (roles?.length > 0 && groups?.length > 0) {
      console.log("tesyicus", render());
    }
  }, [roles, groups]);

  return (
    <>
      {section === "login" && <>{component}</>}
      {permessions?.visibility &&
        render() &&
        (auth ? <RequireAuth> {component}</RequireAuth> : <>{component}</>)}
      {userInfo !== null && (!permessions?.visibility || !render()) && (
        <NotFoundPage />
      )}
    </>
  );
};
