import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,

    interpolation: {
      escapeValue: false,
      format: (value, format, lng, formatOptions) => {
        try {
          if (format === "datetime") {
            return new Intl.DateTimeFormat(lng, {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            }).format(value);
          }
          if (format === "datetime_n") {
            return new Intl.DateTimeFormat(lng, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            }).format(value);
          }
          if (format === "datetime_nr") {
            const options = {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              hour12: false,
            };

            const formattedDate = new Intl.DateTimeFormat(lng, options).format(
              value
            );
            return formattedDate
              .replace(/-/, "/")
              .replace(/(\d+)\/(\d+)\/(\d+)/, "$2/$1/$3");
          }
          if (format === "date_n") {
            return new Intl.DateTimeFormat(lng, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            }).format(value);
          }
          if (format === "date_n") {
            return new Intl.DateTimeFormat(lng, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: undefined,
              minute: undefined,
              second: undefined,
            }).format(value);
          }
          if (format === "number") {
            if (formatOptions && formatOptions.maximumFractionDigits) {
              // Use the provided maximumFractionDigits option

              const formattedNumber = value.toLocaleString(lng, {
                maximumFractionDigits: formatOptions.maximumFractionDigits,
                minimumFractionDigits: formatOptions.minimumFractionDigits,
              });
              return formattedNumber;
            } else {
              // Fallback to the default maximumFractionDigits (e.g., 2)
              const formattedNumber = value.toLocaleString(lng, {
                maximumFractionDigits: 3,
                minimumFractionDigits: 3,
              });
              return formattedNumber;
            }
          }
          /*   if (format === "number") {
            const options = {
              maximumFractionDigits:
                formatOptions &&
                formatOptions.maximumFractionDigits !== undefined
                  ? formatOptions.maximumFractionDigits
                  : value.toString().split(".")[1]?.length || 0,
            };
            const formattedNumber = value.toLocaleString(lng, options);
            return formattedNumber;
          } */
        } catch (ex) {
          return "-";
        }
        return value;
      },
    },
  });

export default i18n;
