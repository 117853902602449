import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector, useDispatch } from "react-redux";
import Card from "@mui/material/Card";
import { Box, Button, Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { setBlockGroupsTrashId } from "../../redux/slices/userGroupSlice";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { useParams } from "react-router-dom";
import { store } from "../../redux/store";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import theme from "../../theme";

const BlockGroups = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [firstRender, setFirstRender] = React.useState(true);
  const [perPage, setPerPage] = React.useState(10);
  const [trashId, setTrashId] = React.useState(null);
  const [value, setValue] = React.useState("");
  const [searchValue, setSearchValue] = React.useState("");
  const [getUserGroups] = store.useLazyGetUserGroupsQuery();
  const [associateGroup, { isSuccess: assocSuccess }] =
    store.useBlockGroupAssociateMutation();
  const [getBlockGroups] = store.useLazyGetBlockGroupsQuery();
  const groups = useSelector((state) => state?.userGroupSlice?.userGroups);
  const blockGroups = useSelector(
    (state) => state?.userGroupSlice?.blockGroups
  );
  const blockGroupsTrashId = useSelector(
    (state) => state?.userGroupSlice?.blockGroupsTrashId
  );
  const blockGroupsCount = useSelector(
    (state) => state?.userGroupSlice?.blockGroupsCount
  );
  const [deleteBlockGroup, { isSuccess: deleteBlockGroupSuccess }] =
    store.useDeleteBlockGroupMutation();
  const options = groups?.map((item) => ({
    label: item?.attributes?.name,
    value: item?.attributes?.id,
  }));
  React.useEffect(() => {
    if (!groups) {
      getUserGroups();
    }
  }, []);
  React.useEffect(() => {
    if (!blockGroups) {
      getBlockGroups({
        id: id,
        params: {
          page: page,
          with_groups: true,
        },
      });
    }
    setFirstRender(false);
  }, []);
  React.useEffect(() => {
    if (!firstRender) {
      getBlockGroups({
        id: id,
        params: {
          page: page,
          with_groups: true,
        },
      });
    }
  }, [page, assocSuccess, deleteBlockGroupSuccess]);

  const onTrashClick = (id) => {
    setTrashId(id);
    setDeleteModal(true);
  };
  React.useEffect(() => {
    console.log("vasdasda", value);
  }, [value]);
  const debValue = debounce((e) => {
    setSearchValue(e.target.value);
  }, 500);
  React.useEffect(() => {
    if (searchValue) {
      getUserGroups({ name: searchValue });
    }
  }, [searchValue]);

  const onAssocClick = () => {
    const attributes = {
      user_group_id: value.value,
    };
    associateGroup({ id: id, attributes: attributes });
  };
  React.useEffect(() => {
    if (blockGroupsTrashId) {
      deleteBlockGroup({ id: id, groupID: blockGroupsTrashId });
    }
  }, [blockGroupsTrashId]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "name",
      headerName: t("name"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <Typography>
            {
              groups?.filter((item) => item.id == params?.value)?.[0]
                ?.attributes?.name
            }
          </Typography>
        );
      },
    },
    {
      field: "description",
      headerName: t("description"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <Typography>
            {
              groups?.filter((item) => item.id == params?.value)?.[0]
                ?.attributes?.description
            }
          </Typography>
        );
      },
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%" }}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <IconButton onClick={() => onTrashClick(params?.value)}>
              <DeleteIcon sx={{ color: theme.palette.error.main }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const rows = blockGroups?.map((item) => ({
    id: item?.id,
    name: item?.attributes?.user_group_id,
    description: item?.attributes?.user_group_id,
    actions: item?.id,
  }));
  React.useEffect(() => {
    if (deleteBlockGroupSuccess) {
      dispatch(setBlockGroupsTrashId(null));

      dispatch(setMessage(t("successfully_deleted")));
    }
  }, [deleteBlockGroupSuccess]);
  return (
    <>
      <DeleteConfirmModal
        trashModal={deleteModal}
        setTrashModal={setDeleteModal}
        text={t("ug_delete_text")}
        title={t("ug_delete_title")}
        deleteFunc={() => setBlockGroupsTrashId(trashId)}
      />
      <Card sx={{ height: 150 }}>
        <CardContent>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            value={value.label}
            options={options ?? []}
            onChange={(e, newValue) => {
              setValue(newValue);
            }}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={debValue}
                label={t("user_groups")}
              />
            )}
          />
          <Button
            onClick={onAssocClick}
            variant="contained"
            sx={{ mt: 3, float: "right" }}
          >
            {t("associate")}
          </Button>
        </CardContent>
      </Card>
      <Box height={600} sx={{ mt: 4 }}>
        <StyledDataGrid
          disableColumnMenu
          rows={rows ?? []}
          columns={columns}
          rowCount={blockGroupsCount}
          pageSize={perPage}
          rowsPerPageOptions={[perPage]}
          disableSelectionOnClick
          onPageChange={(pageNumber) => {
            setPage(pageNumber + 1);
          }}
          page={page - 1}
          paginationMode="server"
          getRowHeight={() => "auto"}
        />
      </Box>
    </>
  );
};

export default BlockGroups;
